import React from "react";
import { useState, useEffect } from "react";
import FileFolderUploader from "components/UploadComponent"
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  // CustomInput,
} from "reactstrap";
import PDFViewer from "components/PDFViewer";
import { Box, Container } from '@mui/material';
import Modal from '@mui/material/Modal';
import DocumentFilesTable from "views/components/DocumentFilesTable";
// import RedactionForm from "views/components/RedactForm";
import VerticalLine from "views/components/VerticalLine";
import { getAllFilenamesAndSizes } from "services/call_backend";
import cleanFilename from "services/file_clean";
import PdfLoadingOverlay from "views/components/PDFLoader";
import Notify from "components/Notify";
import "react-notification-alert/dist/animate.css";

const serverBaseURL = process.env.REACT_APP_BACKEND_URL;

//const serverBaseURL = "https://www.projectdelphi.ai"
//const serverBaseURL = "http://localhost:8000"



const DocumentVault = () => {
  // const [files, setFiles] = useState([]);
  // const [selectedEntities, setSelectedEntities] = useState([]);
  // const [customRedactions, setCustomRedactions] = useState([]);
  // const [showRedactions, setShowRedactions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([])
  const [pdfUrl, setPdfUrl] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const vaultName = "trail-uploads"
  const [loading, setLoading] = useState(true)
  const [viewPdfLoader, setViewPdfLoader] = useState(false)
  const [notification, setNotification] = useState({ message: "", type: "" })


  const handleDismiss = () => {
    setNotification({ message: "", type: "" })
  }


  // const handleSwitchChange = () => {
  //   setShowRedactions(!showRedactions);
  // };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true)
  }

  async function fetchAndLogS3Filenames() {
    setLoading(true)
    try {
      const data = await getAllFilenamesAndSizes();

      if (data) {
        const formattedData = data.map((file) => {

          const size = file["size_in_bytes"]
          const originalFilename = file["file_name"]
          const cleanedFilename = cleanFilename(file["file_name"]); // Clean the filename
          const globalIndex = file["globalIndex"]
          const extension = file["file_name"].split('.').pop().toUpperCase(); // Extract and capitalize the file extension

          const nameWithoutExtension = cleanedFilename.replace(/\.[^/.]+$/, '').split("/").pop(); // Remove the extension


          // Return the formatted dictionary
          return {
            size: size,
            originalFileName: originalFilename,
            document: nameWithoutExtension, // Cleaned filename without extension
            type: extension, // File extension
            view: true, // Static "view" value
            globalIndex: globalIndex
          };
        });

        setLoading(false)
        setTableData(formattedData) // Return the array of dictionaries


      } else {
        console.log("Failed to fetch S3 filenames.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    // handleInitialize("florida")
    fetchAndLogS3Filenames();
  }, []);


  const columns = [
    { label: 'NAME', field: 'document', align: 'text-left' },
    { label: 'TYPE', field: 'type', align: 'text-center' },
    { label: 'FILE SIZE', field: 'size', align: 'text-center' },
    { label: 'VIEW', field: 'view', align: 'text-center' }
  ];

  // const data = [
  //   { name: 'Florida State Wheelchair', type: 'PDF', size: "1.2 MB", view: true },
  //   { name: 'Texas State Report', type: 'DOC', size: "5.2 MB", view: true },
  //   { name: 'Indiana State Wheelchair', type: 'PDF', size: "3.1 MB", view: true },
  //   { name: 'Wisconson State Report', type: 'DOC', size: "0.7 MB", view: true },
  //   { name: 'Missouri State Wheelchair', type: 'PDF', size: "0.2 MB", view: true },
  //   { name: 'Mississippi State Report', type: 'DOC', size: "1.8 MB", view: true },
  //   { name: 'Michigan State Wheelchair', type: 'PDF', size: "1.2 MB", view: true },
  //   { name: 'Ohio State Report', type: 'DOC', size: "3.2 MB", view: true },
  //   { name: 'Illinios State Wheelchair', type: 'PDF', size: "0.8 MB", view: true },
  //   { name: 'Oregon State Report', type: 'DOC', size: "7.2 MB", view: true },
  //   { name: 'Washington State Wheelchair', type: 'PDF', size: "1.8 MB", view: true },
  //   { name: 'California State Report', type: 'DOC', size: "8.1 MB", view: true },
  //   { name: 'New York State Wheelchair', type: 'PDF', size: "4.2 MB", view: true },
  //   { name: 'Maine State Report', type: 'DOC', size: "6.5 MB", view: true },
  //   // Add more data rows as needed
  // ];



  return (
    <>
      <div className="content" style={{ height: "100vh" }} >
        <Notify
          message={notification.message}
          type={notification.type}
          handleDismiss={handleDismiss}

        />
        {viewPdfLoader ? (


          <PdfLoadingOverlay onClose={closeModal}></PdfLoadingOverlay>

        ) : (
          <Modal
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              'display': 'flex',
              'justifyContent': 'center',
              'alignItems': 'center',
              'position': ' fixed',
              'top': 0,
              'left': 0,
              'width': '100%',
              'height': '100%',
              'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
              'z-index': 1000 /* Ensure it's on top */
            }}
          >
            <Box  >
              <Container >
                <PDFViewer pdfUrl={pdfUrl} />
              </Container>
            </Box>
          </Modal>
        )}

        <Row >
          <Col lg="7" md="12">
            <div className="header text-left" style={{ marginLeft: "10%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
              <h4 style={{ color: "#19AECA", fontWeight: 'bold', letterSpacing: .15, fontFamily: "Helvetica" }}><i className="tim-icons icon-single-copy-04" style={{ color: "#19AECA", marginRight: 10, marginLeft: "-10%", fontWeight: 600, fontSize: 24 }} /> Document Vault</h4>
            </div>

            <Card className="card-tasks text-left card-plain scroll-column" style={{ marginTop: 0, paddingTop: 0, height: "100vh" }}>
              <CardBody >
                <Row>
                  <Col lg="6" md="5" sm="6" Style={{ marginRight: 0, paddingRight: 0 }}>

                    <h6 className="d-inline " style={{ opacity: .8, fontSize: "14px", letterSpacing: '.11em', fontWeight: 600, fontFamily: "Helvetica" }}>DOCUMENT LIBRARY</h6>
                  </Col>
                  <Col lg="6" md="7" sm="6">
                    <InputGroup>
                      <Input placeholder="Search"
                        className="input-gray-placeholder input-gray-text"
                        style={{ paddingLeft: "10px" }}
                        onChange={(e) => setSearchTerm(e.target.value)}>
                      </Input>
                      <InputGroupAddon addonType="append" className="input-gray-text">
                        <InputGroupText>
                          <i className="tim-icons icon-zoom-split input-gray-text" style={{ fontSize: "16px", paddingRight: 0 }}></i>
                        </InputGroupText>
                      </InputGroupAddon>

                    </InputGroup>
                  </Col>

                </Row>

                <DocumentFilesTable loading={loading} columns={columns} data={tableData} searchTerm={searchTerm} itemsPerPage={13} setPdfUrl={setPdfUrl} openModal={openModal} vaultName={vaultName} setViewPdfLoader={setViewPdfLoader} />

              </CardBody>
            </Card>

          </Col>
          <Col className="text-center document-display" lg="5" md="12" style={{ "height": "100vh" }} >
            <VerticalLine color="#ccc" width="2px" />
            <div className="header text-left" style={{ marginBottom: "6%", marginLeft: 20 }}>
              <h4 style={{ marginBottom: "-20px", paddingBottom: 0, fontWeight: "bold", letterSpacing: .15, color: "334572", fontFamily: "Helvetica" }}><i className="tim-icons icon-cloud-upload-94" style={{ color: "#334572", marginRight: 10, fontWeight: 600, fontSize: 24 }}></i>Document Upload</h4>

            </div>
            <Card className="card-tasks text-left"
              style={{
                background: `linear-gradient(to bottom, #344675 0%, #263148 100%)`,
                borderBottomRightRadius: "15px",
                width: "95%",
                marginLeft: "5%",
                // minHeight: showRedactions ? "100vh" : "35vh",
                minHeight:  "35vh",
                paddingBottom: 20

              }}>
              <CardHeader>
                <div>
                  <h4 className="custom-white">UPLOAD NEW DOCUMENTS </h4>
                </div>
              </CardHeader>


              <CardBody style={{
                padding: "1rem",

                background: `linear-gradient(to bottom, #344675 0%, #263148 100%)`,
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem', // Adds space between elements
              }}>
                {/* <Row id="checkRadios" style={{ marginBottom: 20 }}>
                  <Col lg="12" sm="12" >
                    <CustomInput type="switch"
                      id="switch-redact"
                      label="With Redactions"
                      checked={showRedactions}
                      onChange={handleSwitchChange}
                      className="custom-input-label-white"

                    />
                  </Col>
                </Row> */}
                {/* {showRedactions && (
                  <div>
                    <RedactionForm

                      setSelectedEntities={setSelectedEntities}
                      setCustomRedactions={setCustomRedactions}
                      selectedEntities={selectedEntities}
                      customRedactions={customRedactions} />
                  </div>
                )} */}

                <div className='section' style={{ marginTop: "1rem" }}>

                  {/* <DragNDrop onFilesSelected={setFiles}  /> */}
                  {/* <FormGroup style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        marginLeft: "12%",
                        marginRight: "10%",
                        border: '2px dashed #bbb',
                        borderRadius: '8px',
                        paddingBottom: '20px',
                        paddingTop: "10px",
                        width: "75%",
                        textAlign: 'center',
                        color: '#666',
                      }}
                    >
                      <div><img style={{ height: 50, width: 75 }} src={logo} alt="react-logo" /></div>
                      <p className="custom-white" style={{ fontSize: '1.2em', color: '#344675' }}>Drag files to upload</p>
                      <p className="custom-white" style={{ color: '#344675' }}>or</p>

                      <Button
                        style={{
                          backgroundColor: 'transparent', // Matches the background color (transparent for the example)
                          borderColor: 'var(--primary)', // Uses primary color for the border
                          color: 'white', // Matches text color to primary
                          borderWidth: '2px', // Optional: thicker border for emphasis

                          borderStyle: 'solid',
                        }}
                        outline // Optional: outline style for a more subtle look
                      >Browse Files</Button>
                    </div>
                  </FormGroup> */}

                  <FileFolderUploader UploadURL={`${serverBaseURL}/new-app-api/upload`} setNotification={setNotification} ></FileFolderUploader>
                </div>
              </CardBody>
            </Card>
            <div>
            </div>


          </Col>

        </Row>
      </div>
    </>
  );
};

export default DocumentVault;

