/*!

=========================================================
* Black Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


import DocumentChat from "views/pages/assistants/DocumentChat";

import Assistant from "views/pages/assistants/Assistant";
import DocumentVault from "views/pages/documents/DocumentVault";

import IconGrid from "views/pages/Icons";
import CreateAssistant from "views/pages/assistants/CreateAssistant";
import Account from "views/pages/monitoring/Account";



const routes = [
  {
    path: "/document-chat",
    name: "DOCUMENT CHAT",
    icon: "tim-icons icon-chat-33",
    component: <DocumentChat />,
    layout: "/admin",
  },
  {
    path: "/document-vault",
    name: "DOCUMENT VAULT",
    icon: "tim-icons icon-single-copy-04",
    component: <DocumentVault />,
    layout: "/admin",
  },
  {
    path: "/advance-search",
    name: "ADVANCED SEARCH",

    icon: "tim-icons icon-spaceship",
    component: <IconGrid />,
    layout: "/admin",
  },
  // {
  //   path: "/document-upload",
  //   name: "DOCUMENT UPLOAD",

  //   icon: "tim-icons icon-cloud-upload-94",
  //   component: <DocumentUpload />,
  //   layout: "/admin",
  // },
  {
    path: "/assistant",
    name: "ASSISTANTS",
    icon: "tim-icons icon-bulb-63",
    component: <Assistant />,
    layout: "/admin",
  },
  {
    path: "/assistant-creator",
    name: "ASSISTANT CREATOR",

    icon: "tim-icons icon-components",
    component: <CreateAssistant />,
    layout: "/admin",
  },


  {
    path: "/",
    name: "ACCOUNT",
    icon: "tim-icons icon-single-02",
    component: <Account />,
    layout: "/admin",
  }

];

export default routes;
