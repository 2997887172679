import { React, useState } from 'react';
import { Table, Button, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { MdOutlineRemoveRedEye, MdClose } from "react-icons/md";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ScaleLoader as BeatLoader } from 'react-spinners';
import { viewPdf } from 'services/call_backend';


const DraggableVaultTable = ({ columns,loading, data, droppableId, searchTerm,  setPdfUrl, setViewPdfLoader, openModal, edit = false, itemsPerPage = 4, borderColor = "rgba(255,255,255,.2)" }) => {
  const [tableData, setTableData] = useState(data); // Main dataset
  const [currentPage, setCurrentPage] = useState(1);
  const [abortController, setAbortController] = useState(null);

  const vaultName = "trail-uploads"
  

  // Filter data based on the search term
  const filteredData = searchTerm
    ? tableData.filter(row =>
      columns.some(col => {
        const cellValue = row[col.field];
        return cellValue != null && cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
      })
    )
    : tableData;

  // Calculate total pages based on the filtered data length
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the starting and ending index of the data to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex) || [];

  // Handle page change
  const goToPage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Function to handle removing a row
  const handleRemoveRow = (globalIndex) => {
    // Remove row from the main data (tableData) by recalculating the filtered row index

    const updatedData = tableData.filter((_, index) => index !== globalIndex);
    setTableData(updatedData);

    // Adjust the current page if the last item on the page is deleted and it goes below the current page range
    if (currentPage > Math.ceil(updatedData.length / itemsPerPage)) {
      setCurrentPage(currentPage - 1);
    }
  };


  const handleViewing = (vaultName,filename)=>{
    setViewPdfLoader(true)
    handleViewPDF(vaultName, filename)
  }

  const handleViewPDF = async (vaultName, filename) => {
    try {
        // Abort any existing request before making a new one
      if (abortController) {
          abortController.abort();
      }
      console.log("this is being called")
      setViewPdfLoader(true)
      const newController = new AbortController();
      setAbortController(newController);

        const pdfUrl = await viewPdf(vaultName, filename, newController.signal);
        
       
        if (pdfUrl) {
          setPdfUrl(pdfUrl);
            openModal();
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn('Fetch aborted:', error);
        } else {
            console.error('Error fetching data:', error);
        }
    }
    finally {
      setTimeout(() => setViewPdfLoader(false), 100); // Delay to prevent race conditions
  }
};

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  return (
    <div>

      {loading ?(
                      <BeatLoader
                      color="#e14eca"
                      loading={loading}
                      size={15}
                      aria-label="Loading Spinner"
                      style={{marginLeft:"45%"}}
                      ></BeatLoader>
                    ):(
                      <>
      <Droppable droppableId={droppableId} >
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
    
            <Table style={{ borderBottomRightRadius: "50px"}} className='small'>
              <thead>
                <tr style={{ borderBottom: `2px solid ${borderColor || "gray"}`}}>
                  {columns.map((col, index) => (
                    <th key={index} className={col.align || "text-left"} style={{ color: "white", fontWeight: 500, fontSize: 12 }}>
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>
  
              <tbody>
                {currentData.length > 0 ? (
                  currentData.map((row) => {
                 
                    return(
                    <Draggable key={`${row.document}-${row.globalIndex}`} draggableId={`${row.document}-${row.globalIndex}`} index={row.globalIndex}>
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            borderBottom: `2px solid ${borderColor || 'white'}`,
                            ...provided.draggableProps.style,
                            cursor: "grab",
                           
                          }}
                        >

                          {columns.map((col, colIndex) => (
                            <td key={colIndex} className={col.align || "text-left"} >
                              {col.field === "view" ? (
                                <Button color="link" id={`tooltip_${row.globalIndex}`} title="" type="button" onClick={() => handleViewing(vaultName, row['originalFileName'])}>
                                  <MdOutlineRemoveRedEye style={{ color: "#ccc" }} />
                                  <UncontrolledTooltip delay={0} target={`tooltip_${row.globalIndex}`}>
                                    View
                                  </UncontrolledTooltip>
                                </Button>
                              ) : (
                                <p style={{ color: "white" }}>{row[col.field]}</p>
                              )}
                            </td>
                          ))}
                          {edit && (
                            <td style={{ textAlign: "right" }}>
                              <Button color="link" onClick={() => handleRemoveRow(row.globalIndex)}>
                                <MdClose style={{ color: "red", fontSize: "1.2em", opacity: 0.8 }} />
                              </Button>
                            </td>
                          )}
                        </tr>
                      )}
                    </Draggable>
                  );
                  }) 
                ) : (

                  <td colSpan={columns.length} style={{ textAlign: 'center', color: '#1E92B6' }}>
                    No data available
                  </td>

                )
                }



                {provided.placeholder}
              </tbody>
             
            </Table>
          </div>
        )}
      </Droppable>

      <Pagination style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: 13 }}>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink style={{ color: "white", opacity: .8 }} previous onClick={() => goToPage(currentPage - 1)}>
            Prev
          </PaginationLink>
        </PaginationItem>
        {pageNumbers
    .filter((page) => {
      // Show a maximum of 5 pages, centered around the current page
      const maxVisiblePages = 3;
      const halfRange = Math.floor(maxVisiblePages / 2);

      if (currentPage <= halfRange) {
        // Near the beginning of the pagination
        return page <= maxVisiblePages;
      } else if (currentPage > totalPages - halfRange) {
        // Near the end of the pagination
        return page > totalPages - maxVisiblePages;
      } else {
        // In the middle of the pagination
        return page >= currentPage - halfRange && page <= currentPage + halfRange;
      }
    })
    .map((page) => (
      <PaginationItem key={page} active={page === currentPage}>
        <PaginationLink
          onClick={() => goToPage(page)}
          style={{ color: 'white', opacity: 0.8 }}
        >
          {page}
        </PaginationLink>
      </PaginationItem>
    ))}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink next onClick={() => goToPage(currentPage + 1)} style={{ color: "white", opacity: .8 }}>
            Next
          </PaginationLink>
        </PaginationItem>
      </Pagination>
      </>
       )}
    </div >

  );
};

export default DraggableVaultTable;