import React, { useEffect, useState } from "react";

import {  Routes, Route, Navigate , useLocation, useNavigate} from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
import { useAuth } from "views/pages/authentication/AuthContext"

import { ProgressProvider } from "components/ProgressContext";
import ServerDown from "views/pages/monitoring/ServerDown";
import { VisionUIControllerProvider } from "context";
import { ThemeProvider } from '@mui/material/styles';
import theme from 'assets/theme';


import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import "App.css"





function App() {
    const { pathname } = useLocation();
    const { user } = useAuth()
    const navigate = useNavigate();
    const [serverDown, setServerDown] = useState(false);
  
    useEffect(() => {
      const handleServerDown = () => {
        setServerDown(true);
      };

      window.addEventListener("server-down", handleServerDown);

      return () => {
        window.removeEventListener("server-down", handleServerDown);
      };
    }, []);

    // ✅ Redirect to "/server-down" inside useEffect to avoid conditional rendering issue
    useEffect(() => {
      if (serverDown) {
        navigate("/server-down");
      }
    }, [serverDown, navigate]);

  
    // Setting page scroll to 0 when changing the route
    useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }, [pathname]);


return (
      <ThemeProvider theme={theme}>
      <ProgressProvider>
      <VisionUIControllerProvider>
        <Routes>
          <Route path="/auth/*" element={<AuthLayout />} />
          <Route path="/server-down" element={<ServerDown />} />
          {user ? (
            <>
            <Route path="/admin/*" element={<AdminLayout />} />
            <Route path="/auth/*" element={<AuthLayout />} />
            </>
          ):
          (
            <>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </>
        )
        }
    
        </Routes>
        </VisionUIControllerProvider>
      </ProgressProvider>
      </ThemeProvider>
)
}

export default App;