import React, { useState } from 'react';
import { Viewer, Worker, Icon } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';



// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/bookmark/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

// Define the expand and collapse icons
const ExpandIcon = () => (
    <Icon size={16}>
        <path d="M.541,5.627,11.666,18.2a.5.5,0,0,0,.749,0L23.541,5.627" />
    </Icon>
);

const CollapseIcon = () => (
    <Icon size={16}>
        <path d="M5.651,23.5,18.227,12.374a.5.5,0,0,0,0-.748L5.651.5" />
    </Icon>
);



const PDFViewer = ({ pdfUrl,  highlights = null, bookmark = false, customBookmarks = [], highlight_coordinates = [] }) => {
    // Plugins
    const searchPluginInstance = searchPlugin({ keyword: highlights });
    const bookmarkPluginInstance = bookmarkPlugin();
    const zoomPluginInstance = zoomPlugin();
    // State to track the zoom scale
    const [scale, setScale] = useState(1);
    

    // Handle zoom changes
    const handleZoom = (newScale) => {
        setScale(newScale.scale);
    };

    // Render custom bookmarks
    const renderBookmarkItem = customBookmarks.length > 0
        ? ({ onClickItem, defaultRenderTitle }) => (
            <>
                {customBookmarks.map((bookmark, index) => (
                    <div key={index} style={{ padding: '6px 12px', cursor: 'pointer' }}
                        onClick={() => onClickItem(bookmark.page - 1)}> {/* Page index is zero-based */}
                        {defaultRenderTitle(bookmark.title)}
                    </div>
                ))}
            </>
        )
        : (renderProps) => renderProps.defaultRenderItem(
            renderProps.onClickItem,
            <>
                {renderProps.defaultRenderToggle(<ExpandIcon />, <CollapseIcon />)}
                {renderProps.defaultRenderTitle(renderProps.onClickTitle)}
            </>
        );

    // Define the default layout with bookmarks (if enabled)
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => {
            if (bookmark) {
                return [
                    defaultTabs[0], // Default first tab
                    {
                        content: <bookmarkPluginInstance.Bookmarks renderBookmarkItem={renderBookmarkItem} />,
                        icon: <BookmarkBorderIcon />,
                        title: 'Bookmark',
                    },
                    defaultTabs[2], // Default third tab
                ];
            }
            return defaultTabs;
        },
    });

    const mergeHighlights = (coords) => {
        const merged = [];
        coords.forEach((coord) => {
          let isMerged = false;
          for (const item of merged) {
            // Check if the current coordinate overlaps with an existing merged item
            if (
              coord.x0 < item.x1 &&
              coord.x1 > item.x0 &&
              coord.y0 < item.y1 &&
              coord.y1 > item.y0
            ) {
              // Update the boundaries to encompass both highlights
              item.x0 = Math.min(item.x0, coord.x0);
              item.y0 = Math.min(item.y0, coord.y0);
              item.x1 = Math.max(item.x1, coord.x1);
              item.y1 = Math.max(item.y1, coord.y1);
              isMerged = true;
              break;
            }
          }
          if (!isMerged) {
            merged.push(coord);
          }
        });
        return merged;
      };

    // Function to render highlights based on coordinates
    const renderHighlights = (props) => {
        const mergedCoordinates = mergeHighlights(
            highlight_coordinates.filter((coord) => coord.pageIndex === props.pageIndex)
          );
          return(
        <div>
            {mergedCoordinates
                .map((coord, idx) => (
                    <div
                        key={idx}
                        className="highlight-area"
                        style={{
                            position: 'absolute',
                            backgroundColor: 'rgba(15, 209,209,.65)',
                            opacity: 0.5,
                            left: `${coord.x0 * scale}px`,
                            top: `${coord.y0 * scale}px`,
                            width: `${(coord.x1 - coord.x0) * scale}px`,
                            height: `${(coord.y1 - coord.y0) * scale}px`,
                            pointerEvents: 'none',
                        }}
                    />
                ))}
        </div>
    )};

    const highlightPluginInstance = highlightPlugin({
      renderHighlights,
      trigger: Trigger.None,
  });

    if (!pdfUrl) {
        return <div>Loading PDF...</div>;
    }
    // let enableDefaultLayout, enableBookmarks, enableSearch, enableHighlight, enableZoom;
    // console.log("This is hightlight in PDFViewer ", highlight_coordinates)
    // if (!highlights && !highlight_coordinates){
    //     enableDefaultLayout = true;
    //     enableBookmarks = false;
    //     enableSearch = true;
    //     enableHighlight = false;
    //     enableZoom = true;
    // }
    // else{
    //     enableDefaultLayout = true;
    //     enableBookmarks = true;
    //     enableSearch = true;
    //     enableHighlight = true;
    //     enableZoom = true;

    // }
    // const plugins = [
    //     enableDefaultLayout && defaultLayoutPlugin,
    //     enableBookmarks && bookmarkPlugin,
    //     enableSearch && searchPlugin,
    //     enableHighlight && highlightPlugin,
    //     enableZoom && zoomPlugin,
    // ].filter(Boolean); 

    // console.log("This is pdf plugin ", plugins)
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.3)', display: 'flex', height: '100%' }}>
              <div style={{ flex: 1, height: '90vh', width: bookmark ? '70vw' : '85vw', marginTop: '5vh' }}>
              {/* <div style={{
                    flex: 1,
                    height: '90vh',
                    width: bookmark ? '70vw' : '85vw',
                    overflow: 'auto', // Enable scrolling
                    marginTop: '5vh'
                }}
              > */}
                  <style>{`.rpv-search__highlight { background-color: rgba(15, 209,209, 0.65) !important; }`}</style>
                  <Viewer
                      fileUrl={pdfUrl}
                      defaultScale={scale}
                    
                      onZoom={handleZoom}
       
                      plugins={[
                        defaultLayoutPluginInstance,
                        bookmarkPluginInstance,
                        searchPluginInstance,
                        highlightPluginInstance,
                        zoomPluginInstance,
                    ]}
                  />
              </div>
          </div>
      </Worker>
  );
};

export default PDFViewer;
           

   
