

import Login from "views/pages/authentication/Login";
import SignUp from "views/pages/authentication/SignUp";


const routes_login = [
    {
      path: "/login",
      name: "Sign In",
  
      icon: "tim-icons icon-chart-pie-36",
      component: <Login/>,
      layout: "/auth",
    },
      {
        path: "/register",
        name: "Sign Up",
    
        icon: "tim-icons icon-components",
        component: <SignUp />,
        layout: "/auth",
      },
      
  
  
  
  ];
  export default routes_login