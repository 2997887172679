/*!

=========================================================
* Black Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { React, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

// reactstrap components
import {

  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,

} from "reactstrap";

import CustomTablePg from "views/components/CustomTablePg";
import { IoFolderOutline } from "react-icons/io5";
import icons from 'variables/icons.js';
import PulseLoader from "react-spinners/PulseLoader";
import { Typography } from '@mui/material';
import Typewriter from 'components/Typewrite/typewrite';
import PDFViewer from "components/PDFViewer";
import { Box, Container} from '@mui/material';
import Modal from '@mui/material/Modal';
import {getAssistantFilenames, viewPdf}from "services/call_backend";
import PdfLoadingOverlay from "views/components/PDFLoader";

import cleanFilename from "services/file_clean";
const serverBaseURL = process.env.REACT_APP_BACKEND_URL;

//const serverBaseURL = "https://www.projectdelphi.ai"
//const serverBaseURL = "http://localhost:8000"


const DocumentChat = () => {
  const [firstName, setFirstName] = useState("Knowli User")
  const [searchTerm, setSearchTerm] = useState("");
  const [messages, setMessages] = useState([]); // Array of timeline messages
  const [input, setInput] = useState(""); // Text input from the user

  const location = useLocation();
  const params = location.state?.params || {};
  const [returnedMainFiles, setReturnedMainFiles]= useState([])
  const [data, setData] = useState(null);
  const [thread_id, setThread_id] = useState('');
  const vectorStoreId = params.vector_id ||"vs_67b49b7dd3888191935c1a0507a0acca" ;
  const assistantName = params.name || "Knowli Handbook Assistant"
  const [loading, setLoading] = useState(false)
  const [loadingTable, setLoadingTable] = useState(true)
  const vaultName = "trail-uploads"
  const [assistantId, setAssistantId] = useState(params.assistant_id ||"asst_zsKp8aoUbIJTE4sQZjknB2TX" )
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(false);
  const [viewPdfLoader, setViewPdfLoader] = useState(false)
  // const [handle_loading_message, setHandleLoadingMessage] = useState(false)

  const [coordinates, setCoordinates] = useState([])
  const [startPage, setStartPage] = useState(null)
  const [pdfUrl, setPdfUrl] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const scrollableRef = useRef(null);


  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
      // scrollableRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Trigger scroll when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchData = async () => {
 
    setLoading(true)
   
    setFirstName("knowli-User")
    try {
      await Promise.all([
        handleInitialize(),
        fetchAndLogS3Filenames(vectorStoreId),
        setRefresh(!refresh)
      ]);
    } catch (error) {
      console.error("Error during parallel fetching:", error);
    }
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // handleInitialize("florida")
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleOpen = async (citation) => {
    setViewPdfLoader(true)
    const filename = citation.file_name;
    const vault_name = "trial-junk-uploads"
    citation = data["results"]
  

    let needed_citation = filterAndExtractSentences(citation, filename)

    setCoordinates(needed_citation.sentences)
    setStartPage(needed_citation.highestScorePage)
    try {
      const pdfUrl = await viewPdf(vault_name, filename);
      setPdfUrl(pdfUrl);
      // Assume highlightsData is already in the correct format
      if (pdfUrl) {
        setViewPdfLoader(false)
        openModal()
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const filterAndExtractSentences = (data, selectedFile) => {
    let sentences = [];

    const filteredItems = data.filter(item => item.file === selectedFile).map(item => ({
      x0: item.x0,
      y0: item.y0,
      x1: item.x1,
      y1: item.y1,
      pageIndex: item.pageIndex
    }))
      .filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.x0 === item.x0 &&
          t.y0 === item.y0 &&
          t.x1 === item.x1 &&
          t.y1 === item.y1 &&
          t.pageIndex === item.pageIndex
        )))

    let highestScorePage = null;

    if (filteredItems.length > 0) {
      // Use reduce to find the item with the maximum score
      const highestScoreItem = filteredItems.reduce((maxItem, currentItem) => {
        return (currentItem.score > maxItem.score) ? currentItem : maxItem;
      }, filteredItems[0]);

      highestScorePage = highestScoreItem.pageIndex;
    }

    // Concatenate the filtered sentences
    sentences = sentences.concat(filteredItems);


    return {
      sentences,
      highestScorePage
    }
  }

  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setCoordinates([])
    setStartPage(null)
    setModalIsOpen(false);


  };

  const toTitleCase = (str) => {
    return str
    .split(" ") // Split the string into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
    .join(" "); // Join the words back into a single string
  };

  const parseFilename = (filename)=>{
    // Decode the URL-encoded parts of the filename
    const decodedFilename = decodeURIComponent(filename);

    // Remove the file extension and replace underscores with spaces
    const nameWithoutExtension = decodedFilename
        .replace(/\.[^/.]+$/, '') // Remove extension
        .replace(/_/g, ' ');      // Replace underscores with spaces

    // Get the file extension in uppercase for the type
    const fileType = decodedFilename.split('.').pop().toUpperCase();

    // Construct the dictionary
    return {
        originalFileName: decodedFilename,
        document: nameWithoutExtension,
        type: fileType,
        view: true
    };
}


 const parseFileArray = (fileArray)=>{
    const uniqueDocuments = new Set(); // Track unique document names
   
    return fileArray
        .map(item => {
            if (item.main_file) {
                return parseFilename(item.main_file);
            }
            return null; // Handle cases where `main_file` is missing
        })
        .filter(parsed => parsed !== null) // Remove null values
        .filter(parsed => {
            // Check if document is already in the set
            if (uniqueDocuments.has(parsed.document)) {
                return false; // Skip duplicate
            }
            uniqueDocuments.add(parsed.document); // Add to the set
            return true; // Keep unique entry
        });
}


  const handleInitialize = async () => {

    try {
     
      const response = await fetch(`${serverBaseURL}/openai-api/get-initial-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName: firstName, assistant_id: `${assistantId}`, assistant_name: `${assistantName}` }),
      });

      if (!response.ok) {
        if (response.status >= 500) {
            window.dispatchEvent(new Event("server-down"));
          }
    }
      setLoading(false)

      const data = await response.json();
      setThread_id(data.thread_id)
      setAssistantId(data.assistant_id)
      // setHandleLoadingMessage(false)
      // setMessages(data.message)
 
      typeOut(data.message, assistantName);

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      window.dispatchEvent(new Event("server-down"));
      throw error;
    }
  }


  const handleSend = () => {

    // setHandleLoadingMessage(true)

    if (input.trim() !== '') {
      const newMessage = { user: 'You', text: input, time: new Date().toLocaleTimeString() };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const query = input
      setInput('');
      setLoading(true)

      handleResponse(query)
    }
  };

  const handleResponse = async (query) => {

    if (query === "") {

      return
    }


    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 120000);
    try {

      const response = await fetch(`${serverBaseURL}/openai-api/continue-chat-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ thread_id: thread_id, new_message: query, assistant_id: assistantId}),
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        if (response.status >= 500) {
            window.dispatchEvent(new Event("server-down"));
          }
    }

      const data = await response.json();
      const new_main_files = (parseFileArray(data.message.citations))
    
      setReturnedMainFiles(new_main_files)

      setData(data)
      setLoading(false)
      typeOut(data.message, assistantName);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error('Fetch request timed out');
        const text_returned = {};
        text_returned.message = "Request timed out. Please try again.";
        typeOut(text_returned, assistantName)
      } else {
        console.error('There was a problem with the fetch operation:', error);
        const text_returned = {};
        text_returned.message = 'There seems to be a problem with this assisant. Please try again later or make a new assistant. If problem persist please contact IT'
        typeOut(text_returned, assistantName)
      }
    }
  }
  useEffect(() => {

    setRefresh((prev)=>!prev)
}, [tableData, returnedMainFiles]);

  async function fetchAndLogS3Filenames(vectorStoreId) {
    try {
        const data = await getAssistantFilenames(vectorStoreId);
        
        if (data) {
          const formattedData = data.map((filename) => {
            const originalFilename = filename.document
            const cleanedFilename = cleanFilename(filename.document); // Clean the filename
            const extension = filename.document.split('.').pop().toUpperCase(); // Extract and capitalize the file extension
            const nameWithoutExtension = cleanedFilename.replace(/\.[^/.]+$/, ''); // Remove the extension
  
            // Return the formatted dictionary
            return {
                originalFileName: originalFilename,
                document: nameWithoutExtension, // Cleaned filename without extension
                type: extension, // File extension
                view: true, // Static "view" value
            };
        });
       
        setLoadingTable(false)
        setTableData(formattedData) // Return the array of dictionaries


        } else {
            console.log("Failed to fetch S3 filenames.");
        }
    } catch (error) {
        console.error("Error:", error);
    }
}





  const typeOut = (text_returned, assistantName) => {
    // setHandleLoadingMessage(false)
    const assistant_title = toTitleCase(assistantName)
    const text = text_returned.message
    const citations = text_returned.citations
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];

      if (lastMessage && lastMessage.user === `${toTitleCase(assistant_title)}`) {
        const updatedMessage = {
          ...lastMessage,
          text: lastMessage.text + text + (/[.!?]$/.test(text) ? ' ' : '')  // Add a space only if the text ends with a punctuation mark
        };
        return [...prevMessages.slice(0, -1), updatedMessage];
      } else {

        let user_message = `${toTitleCase(assistant_title)}`
        const newMessage = {
          user: user_message,
          text: text + (/[.!?]$/.test(text) ? ' ' : ''),  // Add a space only if the text ends with a punctuation mark
          time: new Date().toLocaleTimeString(),
          citations: citations
        };

        return [...prevMessages, newMessage];
      }
    });
  }






  const columns = [
    { label: 'DOCUMENT', field: 'document', align: 'text-left' },
    { label: 'TYPE', field: 'type', align: 'text-center' },
    { label: 'VIEW', field: 'view', align: 'text-center' }
  ];





  return (
    <>
      <div className="content" style={{ height: "95vh" }}>

        <Row >
          <Col md="8">
            <div className="header text-left" style={{ marginLeft: "10%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
              <h4 style={{ color: "#19AECA", fontWeight: '550', letterSpacing: .15, fontFamily: "Helvetica" }}><i className={"tim-icons " + icons[55].name} style={{ color: "#19AECA", marginRight: 10, marginLeft: "-10%", fontWeight: 600, fontSize: 24 }} />{assistantName.toUpperCase()}</h4>
            </div>
            {viewPdfLoader ?
            (
                     
                           
                      <PdfLoadingOverlay onClose={closeModal}></PdfLoadingOverlay>
            
                    ):(
            <Modal
              open={modalIsOpen}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'position': ' fixed',
                'top': 0,
                'left': 0,
                'width': '100%',
                'height': '100%',
                'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
                'z-index': 1000 /* Ensure it's on top */
              }}
            >
              <Box  >
                <Container >
                  <PDFViewer pdfUrl={pdfUrl} highlight_coordinates={coordinates} start_page={startPage} />
                </Container>
              </Box>
            </Modal>
              )}
            <Card className="card-timeline card-plain scroll-column"  style={{ marginTop: 0, paddingTop: 0, height: "70vh" }}>
              <CardBody >
              
                <ul className="timeline" ref={scrollableRef}>
                  {messages.map((message, index) => (
                    <li key={index} className={message.user === "You" ? "" : "timeline-inverted"} >
                      {message.user === "You" ? (
                        <></>
                      ) : (
                        <div className="timeline-badge">
                          <i className={"tim-icons " + icons[71]?.name} />
                        </div>
                      )}
                      <div className="timeline-panel">
                        <div className="timeline-body">
                          {/* <p className={message.user==="You"? "timeline-black-text" : "timeline-white-text"}> */}
                          <Typography className={message.user === "You" ? "timeline-black-text" : "timeline-white-text"} sx={{ fontSize: 12, fontWeight: 700, color: "#fff" }}>{message.user}</Typography>
                          <Typography className={message.user === "You" ? "timeline-black-text" : "timeline-white-text"} sx={{ fontSize: 12, color: "#fff" }}>{message.user === "You" ? message.text : <Typewriter text={message.text} delay={50} />}</Typography>
                          {message.citations && message.citations.length > 0 && (
                            message.citations.map((citation, citationIndex) => (
                              <Typography
                                variant="body2"
                                component="a"
                                className={message.user === "You" ? "timeline-black-text" : "timeline-white-text"}
                                key={citationIndex}
                                onClick={() => handleOpen(citation)}
                                sx={{ fontSize: 11, cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
                              >
                                <Typewriter text={citation.printed_file_name + "\n"} delay={70} sx={{ "display": 'block' }} />
                              </Typography>
                            ))
                          )}
                          <Typography variant="caption" display="block" sx={{ color: message.user === "You" ? '#333' : '#fff' }}>
                            {message.time}
                          </Typography>

                        </div>
                      </div>
                    </li>
                  ))}
                  {loading && (
                    <li key={messages.length} className="timeline-inverted">
                      <div className="timeline-badge">
                        <i className={"tim-icons " + icons[71]?.name} />
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-body">
                          <PulseLoader color="#ffffff" loading={loading} size={15} />

                        </div>
                      </div>

                    </li>

                  )}

                </ul>
             
                {/* <div style={{ display: "flex", marginTop: "1rem" }}>
          <Input
            type="text"
            placeholder="Type your message..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            style={{ flex: 1, marginRight: "0.5rem" }}
          />
          <Button color="primary" onClick={handleAddMessage}>
            Send
          </Button>
        </div> */}
              </CardBody>
            </Card>
            <Row className="align-items-center" style={{ flexWrap: "nowrap" }}>
              <Col xs="9" sm="10" md="10" style={{ marginLeft: "5%" }}>
                <Input className="chat-input"
                  style={{
                    paddingLeft: 20,
                    margin: "auto",
                    height: "100px",
                    marginBottom: "30px",
                    marginTop: "-20px",
                    width: "100%",
                    marginLeft: 20
                  }}
                  type="textarea"
                  placeholder="Type a message"
                  value={input}
                  onChange={(e) => { setInput(e.target.value) }}
                ></Input>
              </Col>
              <Col xs="3" sm="2" md="1" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-5px", marginTop: "-40px" }}>
                <i onClick={handleSend} className="tim-icons icon-send" style={{ color: "#777", fontSize: "30px", opacity: .7, fontWeight: "100", alignItem: "center", marginTop: 0 }}></i>
              </Col>
            </Row>
          </Col>
          <Col className="text-center document-display" md="4" >
            <div className="header text-left" style={{ marginLeft: "10%", marginBottom: "6%" }}>
              <h4 style={{ marginBottom: "-20px", paddingBottom: 10, marginLeft: -30, fontWeight: "bold", letterSpacing: .15, color: "334572", fontFamily: "Helvetica" }}><IoFolderOutline fontWeight={600} fontSize={24} style={{ marginRight: 10, fontWeight: "bold" }} />Assistant Document Collection</h4>

            </div>
            <Card className="card-tasks text-left" style={{ background: `linear-gradient(to bottom, #344675 0%, #263148 100%)`, height: "45vh", borderBottomRightRadius: "15px" }}>
              <CardHeader>
                <Row>
                  <Col lg="6" md="5" sm="6" Style={{ marginRight: 0, paddingRight: 0 }}>

                    <h6 className="d-inline " style={{ color: 'white', opacity: .9, fontSize: "14px", letterSpacing: '.11', fontWeight: 300 }}>{assistantName.toUpperCase()} DOCUMENTS</h6>
                  </Col>
                  <Col lg="6" md="7" sm="6">
                    <InputGroup>
                      <Input placeholder="Search"
                        className="input-gray-placeholder input-gray-text"
                        style={{ paddingLeft: "10px" }}
                        onChange={(e) => setSearchTerm(e.target.value)}>
                      </Input>
                      <InputGroupAddon addonType="append" className="input-gray-text">
                        <InputGroupText>
                          <i className="tim-icons icon-zoom-split input-gray-text" style={{ paddingRight: 0 }}></i>
                        </InputGroupText>
                      </InputGroupAddon>

                    </InputGroup>
                  </Col>
                </Row>

              </CardHeader>
              <CardBody className="scroll-column" style={{ borderBottomRightRadius: "50px" }}>

                <CustomTablePg loading={loadingTable} key={refresh} columns={columns} data={tableData} searchTerm={searchTerm} openModal={openModal} setPdfUrl={setPdfUrl} vaultName={vaultName} itemsPerPage = {5} setViewPdfLoader={setViewPdfLoader} />
              </CardBody>
            </Card>
            <div></div>
            <Card className="card-tasks text-left" style={{ background: `linear-gradient(to bottom, #344675 0%, #263148 100%)`, maxHeight: "35vh", borderBottomRightRadius: "15px" }}>
              <CardHeader>
                <Row>
                  <Col md="10" Style={{ marginRight: 0, paddingRight: 0 }}>

                    <h6 className="d-inline text-center" style={{ color: 'white', opacity: .9, fontSize: "14px", letterSpacing: '.11', fontWeight: 300 }}>REFERENCED DOCUMENTS</h6>
                  </Col>


                </Row>
              </CardHeader>
              <CardBody className="scroll-column" style={{ borderBottomRightRadius: "50px" }}>
                {/* <div className="table-responsive scroll-column" style={{borderBottomRightRadius:"50px"}}> */}
                <CustomTablePg  key={refresh} columns={columns} data={returnedMainFiles} searchTerm={searchTerm} openModal={openModal} setPdfUrl={setPdfUrl} vaultName={vaultName} setViewPdfLoader={setViewPdfLoader}/>
                {/* </div> */}
              </CardBody>
            </Card>

          </Col>
        </Row>
      </div>
    </>
  );
};

export default DocumentChat;
