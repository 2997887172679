import { React, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import {
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,


} from "reactstrap";

import { IoFolderOutline } from "react-icons/io5";
import AssistantsTable from "views/components/AssistantsTable";
import CustomTablePg from "views/components/CustomTablePg";
import DocumentFilesTable from "views/components/DocumentFilesTable";
import PDFViewer from "components/PDFViewer";
import { Box, Container } from '@mui/material';
import Modal from '@mui/material/Modal';
import VerticalLine from "views/components/VerticalLine";
import { getAllFilenames } from "services/call_backend";
import cleanFilename from "services/file_clean";
import { getAllAssistantNames, getAssistantFilenames, getCreateUpdateAssistantsStatus } from "services/call_backend";
import PdfLoadingOverlay from "views/components/PDFLoader";
// import logo from "assets/img/Upload.png";



const Assistant = () => {

    const [searchTerm, setSearchTerm] = useState("");
    const [tableData, setTableData] = useState([])
    const [loadingAllFiles, setLoadingAllFiles] = useState(true)
    const [loadingAssistants, setLoadingAssistants] = useState(true)
    const [loadingAssistantFiles, setLoadingAssistantsFiles] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [rows, setRows] = useState([])
    const [rowSelect, setRowSelect] = useState(null)
    const [selectedBot, setSelectedBot] = useState("")
    const [selectedBotData, setSelectedBotData] = useState("")
    const [abortController, setAbortController] = useState(null);
    const [viewPdfLoader, setViewPdfLoader] = useState(false);
    const [assistantData, setAssistantData] = useState([])
    const [isPolling, setIsPolling] = useState(false)
    const [noAssistants, setNoAssistants] = useState(false)


    const navigate = useNavigate()

    const columns = [
        { label: 'DOCUMENT', field: 'document', align: 'text-left' },
        { label: 'TYPE', field: 'type', align: 'text-center' },
        { label: 'VIEW', field: 'view', align: 'text-center' }
    ];



    async function fetchAndLogAllFilenames() {
        setLoadingAllFiles(true)
        try {
            const data = await getAllFilenames();
            const formattedData = data.map((filename) => {
                const originalFilename = filename.document
                const globalIndex = filename.globalIndex
                const cleanedFilename = cleanFilename(filename.document); // Clean the filename
                const extension = filename.document.split('.').pop().toUpperCase(); // Extract and capitalize the file extension
                const nameWithoutExtension = cleanedFilename.replace(/\.[^/.]+$/, ''); // Remove the extension

                // Return the formatted dictionary
                return {
                    originalFileName: originalFilename,
                    document: nameWithoutExtension, // Cleaned filename without extension
                    type: extension, // File extension
                    view: true, // Static "view" value
                    globalIndex: globalIndex,
                };
            });


            setTableData(formattedData) // Return the array of dictionaries
            setLoadingAllFiles(false)

        } catch (error) {
            console.error("Error:", error);
        }
    }


    function addIconToDicts(arrayOfDicts) {
        /**
         * Adds an 'icon' key with value 'tim-icons icon-chat-33' to each dictionary in the array.
         *
         * @param {Array} arrayOfDicts - An array of dictionaries (objects).
         * @returns {Array} The modified array with the 'icon' key added to each dictionary.
         */
        return arrayOfDicts.map(dict => {
            dict["icon"] = "tim-icons icon-chat-33";
            return dict;
        });
    }



    const fetchData = async () => {

        setLoadingAssistants(true)

        try {
            await Promise.all([
                handleInitialize(),
                fetchAndLogAllFilenames(),
                setRefresh(!refresh)
            ]);
        } catch (error) {
            console.error("Error during parallel fetching:", error);
        }
    };


    const openModal = () => {
        setModalIsOpen(true)
    }
    const closeModal = () => {
        setModalIsOpen(false);
    };
    useEffect(() => {

        setRefresh((prev) => !prev)
    }, [tableData, rows]);

    const handleInitialize = async () => {
        try {
            const data = await getAllAssistantNames()
            setLoadingAssistants(false)
            setNoAssistants(false)
            if (data.length > 0) {
                setRows(addIconToDicts(data))
                setRefresh((prev) => !prev)

            }
            else {
                setNoAssistants(true)
            }




        } catch (error) {
            console.error("Error:", error);
        }
    }



    const pollAllStatuses = async () => {
        // Poll upload statuses
        try {
            // Poll assistant table data
            const assistantDataReturn = await getCreateUpdateAssistantsStatus();

            setAssistantData(Array.isArray(assistantDataReturn) ? assistantDataReturn : []);
            // Stop polling if everything is SUCCESS or FAILURE
            // Stop polling if everything is SUCCESS or FAILURE
            let allAssistantsDone = assistantData.length === 0 || assistantData.every(row => ["SUCCESS", "FAILURE"].includes(row.status))



            if (allAssistantsDone) {
                setIsPolling(false); // Stop polling if everything is done
            }

            else {

                const status_lookup = assistantData.reduce((acc, item) => {
                    acc[item.vector_id] = item.status;
                    return acc
                }, {});
                setRows(prevRows =>
                    prevRows.map(row => ({
                        ...row,
                        status: status_lookup[row.vector_id[0] || row.status]

                    })
                    ))
            }
        }
        catch (error) {
            console.error("Error polling statuses:", error);
        }
    };

    useEffect(() => {
        if (isPolling) {
            const interval = setInterval(async () => {
                await pollAllStatuses();
            }, 10000); // Poll every 1 second

            return () => clearInterval(interval); // Cleanup interval on unmount
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPolling]);



    // Function to handle row removal
    const handleChatClick = (row) => {

        const params = { vector_id: row.vector_id[0], assistant_id: row.id, name: row.content }

        navigate("/admin/document-chat", { state: { params } })
    };

    // // Function to handle row removal
    // const handleRemoveRow = (id) => {
    //     setRows(rows.filter((row) => row.id !== id));
    // };

    // Function to handle adding a new row
    const handleAddRow = () => {
        navigate("/admin/assistant-creator")
        // const newRow = { id: rows.length + 1,icon:"tim-icons icon-chat-33", content: "FL Policy Bot", temperature:"Exact",status:"Ready", disabled: false };
        // setRows([...rows, newRow]);
    };

    // const handleEditRow = async (row) => {

    //     // const data = await getAssistantFilenames()
    //     // params ={"temperature": row.temperature, "status": row.status,"name", row.name}
    //     const params = { assistant_id: row.id, temperature: row.temperature, status: row.status, assistantName: row.content, vector_id: row.vector_id, data: selectedBotData }
    //     navigate("/admin/assistant-editor", { state: { params } })
    // }

    // Function to handle row click
    const handleRowClick = (row) => {
        // If the clicked row is already selected, deselect it
        setRowSelect(""); // Reset the selected row
        setSelectedBot(""); // Clear any selected bot
        setLoadingAssistantsFiles(true)
        if (row === rowSelect) {
            row.disabled = false; // Deselect the current row
            setRowSelect(""); // Reset the selected row
            setSelectedBot(""); // Clear any selected bot
        } else {
            // If there was a previously selected row, enable it
            if (rowSelect) {
                rowSelect.disabled = false;
            }
            // Disable the newly selected row
            row.disabled = true;
            setRowSelect(row); // Set the newly selected row
            setSelectedBot(row.content); // Set the bot for the new row
            fetchAssistantS3Filenames(row.vector_id)

        }
    };

    async function fetchAssistantS3Filenames(vectorStoreId) {
        setSelectedBotData([])
        setLoadingAssistantsFiles(true)
        // Abort any previous request
        if (abortController) {
            abortController.abort();
        }

        const newController = new AbortController();
        setAbortController(newController);
        try {
            const data = await getAssistantFilenames(vectorStoreId, newController.signal);

            if (data) {
                const formattedData = data.map((filename) => {
                    const originalFilename = filename.document
                    const cleanedFilename = cleanFilename(filename.document); // Clean the filename
                    const extension = filename.document.split('.').pop().toUpperCase(); // Extract and capitalize the file extension
                    const nameWithoutExtension = cleanedFilename.replace(/\.[^/.]+$/, ''); // Remove the extension

                    // Return the formatted dictionary
                    return {
                        originalFileName: originalFilename,
                        document: nameWithoutExtension, // Cleaned filename without extension
                        type: extension, // File extension
                        view: true, // Static "view" value
                    };
                });

                setLoadingAssistantsFiles(false)
                setSelectedBotData(formattedData) // Return the array of dictionaries


            } else {
                console.log("Failed to fetch S3 filenames.");
            }
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("Request was aborted.");
            } else {
                console.error("Error:", error);
            }

        }
    }


    const getStatusIcon = (status) => {
        if (status === "Processing") {
            return "tim-icons icon-refresh-02"
        }
        else if (status === "Ready") {
            return "tim-icons icon-check-2"
        }
        else {
            return "tim-icons icon-alert-circle-exc"
        }
    }

    const getRowColor = (status) => {
        if (status === "Processing") {
            return "#19AECA"
        }
        else if (status === "Ready") {
            return "green"
        }
        else {
            return "red"
        }
    }





    useEffect(() => {
        // handleInitialize()
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <>
            <div className="content" height="100vh">
                <Row >
                    {viewPdfLoader ? (


                        <PdfLoadingOverlay onClose={closeModal}></PdfLoadingOverlay>

                    ) : (
                        <Modal
                            open={modalIsOpen}
                            onClose={closeModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                'display': 'flex',
                                'justifyContent': 'center',
                                'alignItems': 'center',
                                'position': ' fixed',
                                'top': 0,
                                'left': 0,
                                'width': '100%',
                                'height': '100%',
                                'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
                                'z-index': 1000 /* Ensure it's on top */
                            }}
                        >
                            <Box  >
                                <Container >
                                    <PDFViewer pdfUrl={pdfUrl} />
                                </Container>
                            </Box>
                        </Modal>
                    )}
                    <Col lg="7" md="7" >
                        <div style={{ marginLeft: "10%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
                            <h4 className="header" style={{ fontWeight: 'bold', letterSpacing: .15, fontFamily: "Helvetica" }}><i className="tim-icons icon-bulb-63" style={{ color: "#19AECA", marginRight: 10, marginLeft: "-10%", fontWeight: 600, fontSize: 24 }} />  Assistants</h4>
                        </div>
                        <div className="header text-left" style={{ marginLeft: "0%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
                            <h5 className="headers" style={{ fontWeight: 600, letterSpacing: .15, fontFamily: "Helvetica" }}>  My Assistants</h5>
                        </div>
                        <div className="card-tasks2 text-left" style={{ maxHeight: "30vh" }} >
                            <CardBody className="scroll-column" style={{ borderRadius: "5px", border: "2px solid #ddd", maxHeight: "30vh" }}>
                                {/* <AssistantsTable setSelectedBot={setSelectedBot} rows = {rows} setRows= {setRows}></AssistantsTable> */}
                                {noAssistants ? (
                                    <>
                                        <h3>No Assistants Found</h3>
                                        <h4>Please create an Assistant</h4>
                                        <h4>If this is not expected. Please contact your IT department </h4>
                                    </>
                                ) : (
                                    <AssistantsTable key={refresh}
                                        loading={loadingAssistants}
                                        rows={rows}
                                        setSelectedBot={setSelectedBot}
                                        selectedBotData={selectedBotData}
                                        handleRowClick={handleRowClick}
                                        handleChatClick={handleChatClick}
                                        getRowColor={getRowColor}
                                        getStatusIcon={getStatusIcon}
                                        // handleEditRow={handleEditRow}
                                        // handleRemoveRow={handleRemoveRow}
                                        handleAddRow={handleAddRow}
                                    ></AssistantsTable>
                                )}
                            </CardBody>
                        </div>
                        {selectedBot && (
                            <>
                                <div className="header text-left" style={{ marginLeft: "0%", marginBottom: 0, marginTop: "5%", paddingTop: 0 }}>
                                    <h5 className="headers" style={{ fontWeight: 600, letterSpacing: .15, fontFamily: "Helvetica" }}><IoFolderOutline fontWeight={600} fontSize={18} style={{ marginRight: 10 }} />  {selectedBot} Assistant Documents</h5>
                                </div>
                                <div className="card-tasks text-left" style={{ maxHeight: "40vh" }} >
                                    <CardBody style={{ borderRadius: "5px", maxHeight: "30vh" }}>
                                        <DocumentFilesTable loading={loadingAssistantFiles} columns={columns} data={selectedBotData} openModal={openModal} setPdfUrl={setPdfUrl} setViewPdfLoader={setViewPdfLoader}></DocumentFilesTable>
                                    </CardBody>
                                </div>
                            </>
                        )}

                    </Col>
                    <Col className="text-center document-display" md="5" style={{ marginLeft: "-10px" }} >

                        <VerticalLine color="#ccc" width="2px" ></VerticalLine>

                        <div className="header text-left" style={{ marginLeft: "10%", marginBottom: "6%" }}>
                            <h4 style={{ marginBottom: "-20px", marginLeft: -30, fontWeight: "bold", letterSpacing: .15, color: "334572", fontFamily: "Helvetica" }}><IoFolderOutline fontWeight={600} fontSize={24} style={{ marginRight: 10 }} />Document Vault</h4>

                        </div>
                        <Card className="card-tasks text-left" style={{ background: `linear-gradient(to bottom, #344675 0%, #263148 100%)`, height: "75vh", borderBottomRightRadius: "15px", marginLeft: 20 }}>
                            <CardHeader>
                                <Row>
                                    <Col lg="6" md="5" sm="6" Style={{ marginRight: 0, paddingRight: 0 }}>

                                        <h6 className="d-inline " style={{ color: 'white', opacity: .9, fontSize: "14px", letterSpacing: '.11', fontWeight: 300 }}>DOCUMENT LIBRARY</h6>
                                    </Col>
                                    <Col lg="6" md="7" sm="6">
                                        <InputGroup>
                                            <Input placeholder="Search"
                                                className="input-gray-placeholder input-gray-text"
                                                style={{ paddingLeft: "10px" }}
                                                onChange={(e) => setSearchTerm(e.target.value)}>
                                            </Input>
                                            <InputGroupAddon addonType="append" className="input-gray-text">
                                                <InputGroupText>
                                                    <i className="tim-icons icon-zoom-split input-gray-text" style={{ paddingRight: 0 }}></i>
                                                </InputGroupText>
                                            </InputGroupAddon>

                                        </InputGroup>
                                    </Col>
                                </Row>

                            </CardHeader>
                            <CardBody className="scroll-column" style={{ borderBottomRightRadius: "50px", maxHeight: "75vh" }}>

                                <CustomTablePg key={refresh} loading={loadingAllFiles} data={tableData} columns={columns} searchTerm={searchTerm} edit={true} itemsPerPage={11} openModal={openModal} setPdfUrl={setPdfUrl} setViewPdfLoader={setViewPdfLoader} />
                            </CardBody>
                        </Card>
                    </Col>






                </Row>
            </div>





        </>

    )

}

export default Assistant;