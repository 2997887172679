

const serverBaseURL = "https://www.projectdelphi.ai"
//? const serverBaseURL = "http://localhost:8000"

export const viewPdf = async (vault, file, signal) => {
    const endpoint = `${serverBaseURL}/new-app-api/get-s3-file`;

    try {
        let filename = vault === "all-documents-vault"
            ? `florida_medicaid/${file}`
            : file;

        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ vault_name: vault, file_name: filename }),
            signal // Pass the signal from AbortController
        });

        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn('Fetch request aborted:', error);
        } else {
            
            console.error("Error fetching S3 file:", error);
            window.dispatchEvent(new Event("server-down"));
            throw error;
        }
        return null;
    }
};





export const getAssistantFilenames = async (vector_store_id, signal) => {

    const endpoint = `${serverBaseURL}/openai-api/vector-store-files-names/${vector_store_id}`;

    try {
        const response = await fetch(endpoint, { signal });

        // Check if the response is successful
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

        // Parse the JSON response

        const data = await response.json();



        return data; // Return the response data
    } catch (error) {
        if (error.name === "AbortError") {
            console.log("Fetch aborted.");
        } else {
            console.error(`Error fetching S3 filenames: ${error}`);
            window.dispatchEvent(new Event("server-down"));
            throw error;
        }
      
    }
}


export const getAllFilenames = async () => {

    const endpoint = `${serverBaseURL}/new-app-api/get-all-s3-files`;

    try {

        const response = await fetch(endpoint);

        // Check if the response is successful
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

        // Parse the JSON response
        const data = await response.json();

        return data; // Return the response data
    } catch (error) {
        console.error("Error fetching S3 filenames:", error);
        window.dispatchEvent(new Event("server-down"));
        throw error;
    }
}


export const getAllFilenamesAndSizes = async () => {

    const endpoint = `${serverBaseURL}/new-app-api/get-all-s3-files-size`;

    try {

        const response = await fetch(endpoint);

        // Check if the response is successful
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

        // Parse the JSON response
        const data = await response.json();

        return data; // Return the response data
    } catch (error) {
        console.error("Error fetching S3 filenames:", error);
        window.dispatchEvent(new Event("server-down"));
        throw error;
    }
}


export const getAllAssistantNames = async () => {

    const endpoint = `${serverBaseURL}/openai-api/get-all-assistants`;

    try {

        const response = await fetch(endpoint);


        // Check if the response is successful
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

        // Parse the JSON response
        const data = await response.json();


        return data.assistants; // Return the response data
    } catch (error) {
        console.error("Error fetching S3 filenames:", error);
        window.dispatchEvent(new Event("server-down"));
        throw error;
    }
}

export const createAssistantVectorStore = async (files, vectorName, temperature) => {
    const timestamp= new Date().toISOString()
    const timezone= Intl.DateTimeFormat().resolvedOptions().timeZone
    const endpoint = `${serverBaseURL}/openai-api/create-vector-store-assistant`
    try {
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ files: files, vector_name: vectorName, temperature: temperature , timestamp:timestamp, timezone:timezone})
        })
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

        const data = await response.json()

        return data
    }
    catch (error) {
        console.error("Error connecting to openAi")
        window.dispatchEvent(new Event("server-down"));
        throw error;
    }


}


export const getCreateUpdateAssistantsStatus = async () => {
    const endpoint = `${serverBaseURL}/openai-api/create-upload-assistants-status`
    try {
        const response = await fetch(endpoint)

        if (response.ok) {
            const data = await response.json()

            return data
        }
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

    }
    catch (error) {
        console.error("Error fetching statuses", error)
        window.dispatchEvent(new Event("server-down"));
        throw error;
    }

}


function areArraysOfObjectsEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    // Sort the arrays based on a stable key, e.g., 'id' or another unique identifier
    const sorted1 = [...arr1].sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    const sorted2 = [...arr2].sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

    return sorted1.every((obj, index) => JSON.stringify(obj) === JSON.stringify(sorted2[index]));
}

export const editAssistantVectorStore = async (original_file_list, new_file_list, vector_store_id, temperature, assistant_id, assistantName) => {
    const timestamp= new Date().toISOString()
    const timezone= Intl.DateTimeFormat().resolvedOptions().timeZone
    if (areArraysOfObjectsEqual(original_file_list, new_file_list)) {
        const endpoint = `${serverBaseURL}/openai-api/modify-assistant`
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ assistant_id: assistant_id, temperature: temperature, timestamp:timestamp, timezone: timezone , vector_store_id: vector_store_id[0] , vector_name: assistantName})
            })
            if (!response.ok) {
                if (response.status >= 500) {
                    window.dispatchEvent(new Event("server-down"));
                }
            }

            const data = await response.json()
            if (data.metadata?.error_type ==="conflict"){
                return {message: "Assistant is currently being modified", type: "danger" }; 

            }
    
            console.log("API Response:", data);
            return data;
        }
        catch (error) {
            console.error("Error connecting to openAi")
            window.dispatchEvent(new Event("server-down"));
            throw error;
        }
    }
    else {

        const endpoint = `${serverBaseURL}/openai-api/update-vector-store-assistant`
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ vector_store_id: vector_store_id[0], assistant_id: assistant_id, temperature: temperature, original_file_list: original_file_list, new_file_list: new_file_list, vector_name: assistantName, timestamp:timestamp, timezone: timezone })
            })
            if (!response.ok) {
                if (response.status >= 500) {
                    window.dispatchEvent(new Event("server-down"));
                }
            }

            const data = await response.json()
            if (data.metadata){ 
                if (data[0]?.error === "health") {
                    console.error("API Error:", data[0].error);
                    return { message: "Redis queue is full. Try back later", type: "danger" };  // Handle the notification logic
                } // Handling current incorrect response format
                if (data[0]?.error) {
                    console.error("API Error:", data[0].error);
                    return {message: "Assistant is currently being modified", type: "danger" };  // Handle the notification logic
                }
            }
    
            console.log("API Response:", data);
            return data;
        } catch (error) {
            console.error("Fetch error:", error);
            return "notify";  // Handle network errors
        }
    }


}

export const fetchUploadTasks = async () => {
    // Returns [{"id":uuid_value, "name":value,"task_id":value,"date": value, "status":"","progress":"", "errors":[]}}]
    const endpoint = `${serverBaseURL}/new-app-api/uploaded-files-task-ids`
    try {
        const response = await fetch(endpoint)
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

        // Parse the JSON response
        const data = await response.json();
        return data
    }
    catch (error) {
        console.error("Error connecting to Redis")
        window.dispatchEvent(new Event("server-down"));
        throw error;
    }

}

export const fetchUploadTaskErrors = async () => {
    // Returns [{"file": value,"message":value}]
    const endpoint = `${serverBaseURL}/new-app-api/failed-uploaded-files`

    try {
        const response = await fetch(endpoint)
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }
        // Parse the JSON response
        const data = await response.json();
        return data

    }
    catch (error) {
        console.error("Error connecting to Redis")
        window.dispatchEvent(new Event("server-down"));
        throw error;

    }
}

export const fetchUploadTaskStatuses = async () => {
    // Returns {"task_id":value, "status":value, "result":{"progress": value}}
    const endpoint = `${serverBaseURL}/new-app-api/uploaded-files-task-status`

    try {
        const response = await fetch(endpoint)
        if (!response.ok) {
            if (response.status >= 500) {
                window.dispatchEvent(new Event("server-down"));
            }
        }

        // Parse the JSON response
        const data = await response.json();
        return data

    }
    catch (error) {
        console.error("Error connecting to Redis")
        window.dispatchEvent(new Event("server-down"));
        throw error;

    }

}

