import React, { useEffect, useRef } from "react";
import NotificationAlert from "react-notification-alert";

import "react-notification-alert/dist/animate.css";

const Notify = ({ message, duration = 5000, type = "info", handleDismiss = null }) => {
  const notificationAlertRef = useRef(null);

  const notify = (message, type) => {
    const options = {
      place: "tr", // Top-right corner
      message: (
        <div>
          {message}{" "}
          {handleDismiss && (
            <button
              onClick={handleDismiss}
              style={{
                background: "none",
                border: "none",
                color: "white",
                textDecoration: "underline",
                cursor: "pointer",
                float: "right",
              }}
            >
              Dismiss
            </button>
          )}
        </div>
      ),
      type, // Notification type: "success", "danger", "info", "warning"
      icon: "tim-icons icon-bell-55",
    //   closeButton: true,
    //   autoDismiss: duration / 1000, // Convert milliseconds to seconds
    };

    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    if (message) {
      notify(message, type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, type]);

  return <NotificationAlert ref={notificationAlertRef}/>;
};

export default Notify;