import React, { useState , useEffect} from "react";
// Reuse the ProgressBar component from earlier
import { Table,  Collapse } from 'reactstrap';
import { ScaleLoader as BeatLoader } from 'react-spinners'

// const data2 = [{id:"sldfkj", name:"First", temperature:"Cold", time:"1/1/2000 12:12:12 PM", progress: 10, status:"SUCCESS", errors: []}, 
//   {id:"lakjds", name:"Second", temperature:"Cold", time:"1/1/2000 12:12:12 PM", progress: 10, status:"PROGRESS", errors: []}, 
//   {id:"slsjf", name:"Three", temperature:"Cold", time:"1/1/2000 12:12:12 PM", progress: 100, status:"SUCCESS", errors: []}]


const AssistantMonitoringTable = ({data, loading}) => {
  const [tableData, setTableData] = useState(data || [])




  useEffect(() => {
    setTableData(data || []); // Ensure tableData is always an array
  }, [data]);


 const [expandedRows, setExpandedRows] = useState({}); // Object to track which rows are expanded

 const getStatusIcon = (status) =>{
 
    if(status === "PROGRESS"){
        return "tim-icons icon-refresh-02"
    }
    else if(status === "SUCCESS"){
        return "tim-icons icon-check-2"
    }
    else{
        return "tim-icons icon-alert-circle-exc"
    }
    }

    const getRowColor = (status)=>{
      
        if (status === "PROGRESS"){
            return "#19AECA"
        }
        else if (status === "SUCCESS"){
            return "green"
        }
        else{
            return "purple"
        }
      }

    const getTemperature =(temp)=>{
      if (typeof(temp) == "string"){
        return temp
      }
      if (temp===0){
        return "Exact"
      }
      else if (temp < .8) {
        return "Moderate"
      }
      else {
        return "Flexiable"
      }
    }

 const toggleDetails = (rowIndex) => {
   setExpandedRows((prev) => ({
     ...prev,
     [rowIndex]: !prev[rowIndex], // Toggle the specific row's collapse
   }));
 };

 return (
    <div style={{ width: "100%", border: "1px solid #ddd", borderRadius: "5px" }}>
      {loading ? (
        <BeatLoader
          color="#e14eca"
          loading={loading}
          size={25}
          aria-label="Loading Spinner"
          style={{ marginLeft: "43%" }}
        />
      ) : (
        <Table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
          <tr >
          <th style={{ padding: "10px"}}>{""}</th>
            <th style={{ padding: "10px"}}>Assistant</th>
            <th style={{ padding: "10px"}}>Temperature</th>
            <th style={{ padding: "10px" }}>Create Time</th>
            <th style={{ padding: "10px" }}>Progress</th>
          </tr>
        </thead>
          <tbody>
            {tableData.map((row, index) => ( // ✅ Defined `index` inside `.map()`
              <React.Fragment key={row.id}> 
                <tr>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <i className={getStatusIcon(row.status)} style={{ color: getRowColor(row.status) }} />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <p className="text">{row.name}</p>
                  </td>
                  <td style={{  verticalAlign: "middle" }}>
                    <p className="text">{getTemperature(row.temperature)}</p>
                  </td>
                  <td style={{  verticalAlign: "middle" }}>
                    <p className="text">{row.date}</p>
                  </td>
                  <td style={{verticalAlign: "middle" }}>
                    <progress value={row.progress} />
                  </td>
                </tr>
  
                {/* ✅ Check if `row.errors` exists and has items before mapping */}
                {row.errors && row.errors.length > 0 && (
                  <tr>
                    <td>{"  "}</td>
                    <td colSpan={12}>
                      <div>
                        <a
                          aria-expanded={!!expandedRows[index]}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleDetails(index);
                          }}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "#e14eca",
                          }}
                        >
                          Details{" "}
                          <i
                            className={`tim-icons ${
                              expandedRows[index] ? "icon-minimal-up" : "icon-minimal-down"
                            }`}
                          />
                        </a>
                      </div>
                      <Collapse role="tabpanel" isOpen={!!expandedRows[index]}>
                        {row.errors.map((error, idx) => (
                          <tr key={idx}>
                            <td>{" "}</td>
                            <td>{error}</td>
                            <td>{" "}</td>
                            <td>{" "}</td>
                            <td>{error.message}</td>
                          </tr>
                        ))}
                      </Collapse>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default AssistantMonitoringTable;