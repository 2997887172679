import React, { createContext, useContext, useState } from 'react';
import {useNavigate} from "react-router-dom";
import { CognitoUserAttribute , AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userpool from "userpool";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null)



  
  const navigate = useNavigate();

  const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
  
      const userData = {
        Username: email,
        Pool: userpool, // Ensure userPool is correctly initialized
      };
  
      const cognitoUser = new CognitoUser(userData);
      setCognitoUser(cognitoUser);
  
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          const tokens = {
            idToken: result.getIdToken().getJwtToken(),
            accessToken: result.getAccessToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
          };
          
          // Store tokens in sessionStorage
          sessionStorage.setItem("idToken", tokens.idToken);
          sessionStorage.setItem("accessToken", tokens.accessToken);
          sessionStorage.setItem("refreshToken", tokens.refreshToken);
  
          resolve({ success: true, user: cognitoUser, tokens });
        },
        onFailure: (err) => {
          reject({ success: false, error: err.message, email });
        },
      });
    });
  };
  


const signUp = async(email,password)=> {
  return new Promise((resolve, reject) => {
      
  const attributeList = [];
  const emailAttribute = {
      Name: 'email',
      Value: email
  };
  const userData = {
    Username: email, // Username here is the email used as identifier
    Pool: userpool, // Ensure userPool is correctly initialized elsewhere in your code
  };
  const cognitoUser = new CognitoUser(userData);
  setCognitoUser(cognitoUser)

  attributeList.push(new CognitoUserAttribute(emailAttribute));


  userpool.signUp(email, password, attributeList, null ,(err, result) => {
    if (err) {
      reject({ success: false, error: err, email });
    } else {
      resolve({ success: true, user: result.user });
    }
  });
});
}



    // Sign in after email verification to get the session
    const signInAfterVerification = (email, password) => {
      return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });
    
        const cognitoUser = new CognitoUser({
          Username: email,
          Pool: userpool,
        });
    
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            const tokens = {
              idToken: result.getIdToken().getJwtToken(),
              accessToken: result.getAccessToken().getJwtToken(),
              refreshToken: result.getRefreshToken().getToken(),
            };
    
            // Save tokens in session storage
            sessionStorage.setItem("idToken", tokens.idToken);
            sessionStorage.setItem("accessToken", tokens.accessToken);
            sessionStorage.setItem("refreshToken", tokens.refreshToken);
    
            resolve({ success: true, cognitoUser, tokens });
          },
          onFailure: (err) => {
            reject({ success: false, error: err.message });
          },
        });
      });
    };

    const verifyEmail = (verificationCode) => {
      return new Promise((resolve, reject) => {
        if (cognitoUser) {
          cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
            if (err) {
              reject({ success: false, error: err.message });
            } else {
              resolve({ success: true, result });
            }
          });
        } else {
          reject({ success: false, error: "Cognito user not found. Please sign in again." });
        }
      });
    };



  const setSessions = (cognitoUser) =>{
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        console.error("Failed to get user attributes:", err);
        return;
      }   
      // Loop through the attributes and extract the desired ones
      result.forEach((attribute) => {
        sessionStorage.setItem(attribute.getName(), attribute.getValue())
  })
})}



  const reset = (newPassword, email, verificationCode)=>{

    return new Promise((resolve, reject)=>{

      const cognitoUser = new CognitoUser({Username: email, Pool: userpool})

  
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        
        resolve({success: true, message:"Password reset successful"})
    },
      onFailure: (err) => {

        reject({success: false, error: err.message || "Unable to reset password."})
  }})
})
}

const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    if (!email) {
      reject({ success: false, error: "Please enter an email."});
      return;
    }
 
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userpool,
    });

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve({ success: true, message: "Password reset code sent to email." });
      },
      onFailure: (err) => {
        reject({ success: false, error: err.message || "Error sending reset password code." });
      },

    });
  });
};

 const resendConfirmationCode = (email) =>{
    const username = email
    const userData = {
        Username: username,
        Pool: userpool
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
            console.error("Error resending confirmation code:", err);
        } else {
            toast.error("Verification email resent. Please check your inbox.", {
              position: "top-center",
          
              //onClose: () => navigate('/pages/authentication/sign_in', { replace: true })
            });
            
        }
    });
  }


    

  
    


  const signOut = (signOutPageUrl) => {
    setUser(null);
    sessionStorage.removeItem("user");
    navigate(signOutPageUrl)
  };

  return (
    <AuthContext.Provider value={{ user, signIn, signUp, signOut , verifyEmail, reset, forgotPassword, resendConfirmationCode, setSessions, signInAfterVerification, setUser}}>
      {children}

    </AuthContext.Provider>
  );
};
