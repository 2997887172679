import React, { createContext, useState, useContext } from "react";

// Create the Context
const ProgressContext = createContext();

// Provider Component
export const ProgressProvider = ({ children }) => {
  const [progressUrl, setProgressUrl] = useState(null); // Progress URL for polling
  const [progressData, setProgressData] = useState({
    progress: 0,
    status: "Idle",
  }); // Progress data to be displayed
  const [progressMessage, setProgressMessage] = useState(null)

  return (
    <ProgressContext.Provider value={{ progressUrl, setProgressUrl, progressData, setProgressData , progressMessage, setProgressMessage}}>
      {children}
    </ProgressContext.Provider>
  );
};

// Custom Hook for easier access
export const useProgress = () => useContext(ProgressContext);