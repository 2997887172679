import React from "react";

import { Table } from "reactstrap";

import { ScaleLoader as BeatLoader  } from "react-spinners";



const AssistantsTable = ({loading , rows, handleRowClick, handleChatClick, getRowColor, getStatusIcon, handleAddRow }) => {

  
//   const data2 = [
//     { document: 'Florida State Wheelchair', type: 'PDF', view: true },
//     { document: 'Texas State Report', type: 'DOC', view: true },
//     { document: 'California State Wheelchair', type: 'PDF', view: true },


 
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
       { loading ? (
                
                  <>
                    <BeatLoader
                    color="#e14eca"
                    loading={loading}
                    size={25}
                    aria-label="Loading Spinner"
                    style={{marginLeft:"43%"}}
                    ></BeatLoader>
                    </>
                      
                  ):(
      <Table>
        <tbody>
          {rows.map((row) => (
            <tr
              key={row.id}
              style={{
                backgroundColor: row.disabled ? "lightblue" : "",
              }}
              onClick={() => handleRowClick(row)}
            >
              <td style={{ textAlign: "left", verticalAlign: "middle" }}>
                <i
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleChatClick(row);
                  }}
                  className={"tim-icons " + row.icon}
                  style={{ color: "#19AECA", marginRight: 10, fontWeight: 600, fontSize: 16 }}
                />
              </td>
              <td style={{ textAlign: "left", verticalAlign: "middle" }}>
                <p className="text">{row.content}</p>
              </td>
              <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                <p className="text">{row.temperature}</p>
              </td>
              <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                <p className="text" style={{ color: getRowColor(row.status) }}>
                  <i className={getStatusIcon(row.status)} style={{ color: getRowColor(row.status) }} /> {row.status}
                </p>
              </td>
              {/* <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                <i
                  className="tim-icons icon-pencil"
                  color="link"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleEditRow(row);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </td> */}
              {/* <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                <i
                  className="tim-icons icon-trash-simple"
                  color="link"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleRemoveRow(row.id);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </td> */}
            </tr>
          ))}
          <tr>
            <td colSpan="6" style={{ textAlign: "center", verticalAlign: "middle", width: "100%" }}>
              <button
                className="btn btn-link"
                onClick={handleAddRow}
                style={{ color: "green", fontSize: "1.5em", cursor: "pointer", border: "none", background: "none" }}
              >
                <i className="tim-icons icon-simple-add" />
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
      )}
    </div>
  );
};

export default AssistantsTable;
