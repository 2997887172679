import React, { useState, useRef} from "react";
import {useNavigate } from "react-router-dom";
import JSZip from "jszip";
import { Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from "reactstrap";
import logo from "assets/img/Upload.png";



const FileUploader = ({ UploadURL ,setNotification}) => {
  const [showUploadFiles, setShowUploadFiles] = useState(true);
  const [files, setFiles] = useState([]); // Holds uploaded files
  const [modal, setModal] = useState(false); // Modal state
  const [modalMessage, setModalMessage] = useState(""); // Error message

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0); // Progress state
  const progressIntervalRef = useRef(null);
 
  
  const navigate = useNavigate()

  
 

  const toggleModal = () => setModal(!modal);


  // Process files and check for invalid folder/file names
  const processFiles = (uploadedFiles) => {
   
    const invalidFiles = [];

    uploadedFiles.forEach((file) => {
   

      const fullPath = file.webkitRelativePath || file.name;
      const pathParts = fullPath.split("/");
      const fileName = pathParts.pop(); // Get file name
      const folderPath = pathParts.join("/"); // Folder structure

      if (fileName.length > 200) {
        invalidFiles.push(`File name too long: ${fileName}`);
      }
      if (folderPath.length > 200) {
        invalidFiles.push(`Folder path too long: ${folderPath}`);
      }
    });

    if (invalidFiles.length > 0) {
      setModalMessage(`Issues with file(s):\n${invalidFiles.join("\n")}`);
      setModal(true);
      return false;
    }

    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    return true;
  };

  const handleFileInputChange = (e) => {
  
    const uploadedFiles = Array.from(e.target.files);
    processFiles(uploadedFiles);
  };

  const handleFolderInputChange = (e) => {

    const uploadedFolders = Array.from(e.target.files);
    
    processFiles(uploadedFolders);
  };

  const handleUpload = async () => {
    setNotification({ message: "Please don't navigate away. Browser needs to upload file(s). You will be routed to progress page when done.", type: "danger" })
    setShowUploadFiles(false)
    if (files.length === 0) {
      setModalMessage("No files selected for upload.");
      setModal(true);
      return;
    }

    // const estimatedUploadTime = files.length * 200; // 200ms per file (adjust as needed)
    let totalFileSize;
    totalFileSize = files.reduce((total, file) => total + file.size, 0); // in bytes
    totalFileSize += files.length * 10000
    const uploadSpeed = 60000; // Assumed upload speed in bytes/sec (adjust as needed)
    const estimatedUploadTime = (totalFileSize / uploadSpeed) * 100; 

    
    setUploading(true);
    setProgress(0)
 
   

    try {
   
        // Zip multiple files or folders
        const zip = new JSZip();
        const fullPath = files[0].webkitRelativePath || files[0].name
        const uploadName = fullPath.split("/")[0]
        files.forEach((file) => {
          const relativePath = file.webkitRelativePath || file.name;
          zip.file(relativePath, file);
        });
        startProgressBar(estimatedUploadTime);
        const zipBlob = await zip.generateAsync({ type: "blob" });
        await sendToBackend(zipBlob, "files.zip", uploadName);
        
      

     
      setFiles([]);
      setShowUploadFiles(true)
    } catch (error) {
      console.error("Upload error:", error);
      setModalMessage("Failed to upload files. Please try again.");
      setModal(true);
    } finally {
      setUploading(false);
      clearProgressBar();
    }
  };




  const sendToBackend = async (file, fileName,uploadName) => {
    try {
      const formData = new FormData();
      formData.append("uploadName", String(uploadName));
      formData.append("files", file, fileName);
      console.log("We are in sendToBackend and uploadName ", uploadName)
      

    

      console.log("We are in call upload with url ", UploadURL)
      const response = await fetch(UploadURL, {
        method: "POST",
        body: formData,

      });
      const data = await response.json();
    
      if(data.task_id){
        const params = { tab: "uploads" }
        navigate("/admin", { state: { params } })
      };
  
      if (!response.ok) {
        if (response.status >= 500) {
          window.dispatchEvent(new Event("server-down"));
        }
      }
  

    } catch (error)
    { 
      console.error("Error uploading file:", error.message);
      
      alert(`Failed to upload file: ${fileName}. Error: ${error.message}`);
  
    }
  };


  const startProgressBar = (estimatedUploadTime) => {
    const intervalTime = 100; // Update progress every 100ms
    let currentProgress = 0;
    const maxProgress = 95;
  
    // const progressInterval = setInterval(() => {
    //   if (currentProgress < maxProgress) {
    //     currentProgress += (intervalTime / estimatedUploadTime) * 100;
    //     setProgress(Math.min(maxProgress, Math.round(currentProgress)));
    //   }
    // }, intervalTime);

    // Clear any existing interval before starting a new one
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }

    progressIntervalRef.current = setInterval(() => {
      if (currentProgress < maxProgress) {
        currentProgress += (intervalTime / estimatedUploadTime) * 100;
        setProgress(Math.min(maxProgress, Math.round(currentProgress)));
      }
      if (currentProgress < 99){
        currentProgress += (intervalTime / estimatedUploadTime) * 10;
        setProgress(Math.min(99, Math.round(currentProgress)))
      }
      // currentProgress += (intervalTime / estimatedUploadTime) * 100;
      // setProgress((prevProgress) => Math.min(100, Math.round(currentProgress)));


    }, intervalTime);
  };


  const clearProgressBar = () => {
    // Explicitly clear the interval when needed
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    
    }
  };

  const handleFileClick = () => {

    document.getElementById("file-input").click(); // Trigger the file input click
  };
  const handleFolderClick = () => {
  
    document.getElementById("folder-input").click(); // Trigger the file input click
  };

  return (
    <FormGroup style={{ textAlign: "center" }}>
      {/* Drag and Drop Area */}
      <div
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          const droppedFiles = Array.from(e.dataTransfer.files);
          processFiles(droppedFiles);
        }}
        style={{
          marginLeft: "12%",
          marginRight: "10%",
          border: "2px dashed #bbb",
          borderRadius: "8px",
          paddingBottom: "20px",
          paddingTop: "10px",
          width: "75%",
          textAlign: "center",
          color: "#666",
        }}
      >
        <div>
          <img style={{ height: 50, width: 75 }} src={logo} alt="Upload Logo" />
        </div>
        <p style={{ fontSize: "1.2em", color: "#344675" }}>Drag files or folders here to upload</p>
        <p style={{ color: "#344675" }}>or</p>

        {/* File Upload */}
        <div>
          <input
            type="file"
            multiple
            style={{ display: "none" }}
            id="file-input"
            onChange={handleFileInputChange}
          />
          <Button
            onClick={handleFileClick}
            style={{
              backgroundColor: "transparent",
              borderColor: "var(--primary)",
              color: "white",
              borderWidth: "2px",
              borderStyle: "solid",
              marginRight: "10px",
            }}
            outline
          >
            Browse Files 
          </Button>
        </div>

        {/* Folder Upload */}
        <div style={{ marginTop: "10px" }}>
          <input
            type="file"
            multiple
            webkitdirectory="true"
            directory="true"
            style={{ display: "none" }}
            id="folder-input"
            onChange={handleFolderInputChange}
          />
          <Button
            onClick={handleFolderClick}
            style={{
              backgroundColor: "transparent",
              borderColor: "var(--primary)",
              color: "white",
              borderWidth: "2px",
              borderStyle: "solid",
            }}
            outline
          >
            Browse Folders
          </Button>
        </div>
      </div>

      {/* Selected Files */}
      {files.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <h4 style={{ marginBottom: "30px" }}>Selected Files:</h4>
          {showUploadFiles &&
            <ul
              style={{
                maxHeight: "25vh", // Limits the height to 25% of the viewport height
                overflowY: "auto", // Enables vertical scrolling if content overflows
                border: "1px solid #ddd", // Optional: adds a subtle border
                padding: "10px", // Adds some padding for better readability
                margin: "10px 0", // Optional: adds spacing around the list
                listStyleType: "none", // Optional: removes default bullet points
              }}>
              {files.map((file, index) => (
                <li key={index}>{file.webkitRelativePath || file.name}</li>
              ))}
            </ul>
          }
          <Button color="primary" onClick={handleUpload} disabled={uploading}>
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </div>
      )}
      {/* Progress Bar */}
      {uploading && (
        <div style={{ marginTop: "20px" }}>
          <Progress value={progress} />
          <p>{progress}%</p>
        </div>
      )}

    

      {/* Modal for Errors */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Warning</ModalHeader>
        <ModalBody style={{ whiteSpace: "pre-wrap" }}>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </FormGroup>
  );
};

export default FileUploader;
