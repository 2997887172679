// /*!

// =========================================================
// * Black Dashboard PRO React - v1.2.4
// =========================================================

// * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
// * Copyright 2024 Creative Tim (https://www.creative-tim.com)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// /** 

// =========================================================
// * Vision UI PRO React - v1.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
// * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

// * Design and Coded by Simmmple & Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

// */

import { useState, useEffect, useRef } from "react";



// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";

// authentication components and function
import { useAuth } from "views/pages/authentication/AuthContext";


// @mui material components
import Card from "@mui/material/Card";
import { Modal } from '@mui/material';


// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";


// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";


// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/img/knowli-site-image/Background.png";
import smImage from "assets/img/knowli-site-image/Knowli AI Logo.svg"

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setconfirmNewPassword] = useState('');
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const location = useLocation();
  const notificationAlertRef = useRef()
  const navigate = useNavigate()


  const { signIn, reset, forgotPassword, verifyEmail, resendConfirmationCode, setUser, setSessions, signInAfterVerification } = useAuth();


  const notify = (message, type) => {
    const options = {
      place: "tr",
      message: (
        <div>
          {message}{" "}

        </div>
      ),
      type: type, // "success", "danger", "info", "warning"
      icon: "tim-icons icon-bell-55",
      closeButton: true,
      autoDismiss: 3

    };
    notificationAlertRef.current.notificationAlert(options);

  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleOpenEmailModal = () => setOpenEmailModal(true);
  const handleCloseEmailModal = () => setOpenEmailModal(false);


  const handleSignIn = async (e) => {
    e.preventDefault();

    if (rememberMe) {
      // Save the email to localStorage if rememberMe is checked
      localStorage.setItem('rememberedEmail', email);
    } else {
      // Clear the remembered email if rememberMe is not checked
      localStorage.removeItem('rememberedEmail');
    }
    try {
      const response = await signIn(email, password);

      if (response.success) {
       
        setUser(response.tokens.accessToken);
        setSessions(response.user);

        // Navigate or trigger additional actions
        call_navigation();
      }
    } catch (err) {
      console.error("Sign-in failed:", err.error);

      if (err.error === "User is not confirmed.") {
        handleOpenEmailModal();
        resendConfirmationCode(err.email, handleCloseEmailModal);
      } else {
        notify("Incorrect username or password. Please try again.", "danger");
      }
    }
  };

  const call_navigation = () => {
    navigate("/admin/assistant")
  }





  const handleForgotPassword = async () => {


    try {
      const response = await forgotPassword(email);


      if (response.success) {


        // Open modal for entering the verification code
        setOpenModal(true);
      }
    } catch (err) {
      console.error("Forgot password request failed:", err.error);

      // Show error notification
      notify(err.error, "danger");
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (!passwordsMatch) {
      notify("Passwords do not match!", "danger");
      return;
    }
    try {
      const response = await reset(newPassword, email, verificationCode)

      if (response.success) {
        notify(response.message, "success")
        setOpenModal(false)
      

      }
    }
    catch (err) {
   
      notify(err.error, "danger")
    
    }
  }





  const handleVerifyEmail = async () => {

    if (!verificationCode) {
      notify("Enter Verification Code!", "danger");
      return;
    }
    try {
      const response = await verifyEmail(verificationCode)

      if (response.success) {
        

        // Close the email verification modal
        handleCloseEmailModal();

        // // Open the TOTP setup modal (if needed)
        // handleOpenTOTPModal();

        // Call any additional actions after email verification
        const response2 = await signInAfterVerification(email, password);

        if (response2.success) {
   

          // Navigate to the desired page after successful sign-in
          navigate("/auth/login", {
            replace: true,
            state: { email },
          });

          // Optionally, open the TOTP setup modal

        }
      }
    }
    catch (err) {
      console.error("Email verification failed:", err.error);

      // Display an error notification
      notify(err.error || "Failed to verify email. Please try again.", "danger");
    }
  };




  const handleClose = () => {
    setOpenModal(false); // Function to close the modal
  };
  const handleResendVerification = () => {
    resendConfirmationCode(email)
  }


  // Load the remembered username from localStorage when the component mounts
  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
    }
  }, [])

  useEffect(() => {
    // Check if there is an email in the state
    if (location.state?.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  useEffect(() => {
    // Check if passwords match whenever password or confirmPassword changes
    setPasswordsMatch(newPassword === confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  return (
    <CoverLayout
      title="Welcome Back"
      color="white"
      description="Enter your email and password to sign in"
      image1={bgImage}
      image2={smImage}

    >
      <VuiBox component="form" role="form" onSubmit={handleSignIn}>
        <NotificationAlert ref={notificationAlertRef} />
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="email"
              placeholder="Your email..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>

        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth type="submit">
            SIGN IN
          </VuiButton>
        </VuiBox>
        <VuiBox>
          <VuiTypography variant="button" color="text">
            Forgot Password?{" "}
            <VuiTypography
              onClick={handleForgotPassword}
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
              sx={{ cursor: 'pointer' }}
            >
             Reset Password
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/auth/register"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>

      <Modal open={openEmailModal} onClose={handleCloseEmailModal} style={{ bgColor: 'white', width: '400px', margin: 'auto', marginTop: '50px' }}>
        <Card sx={{ paddingBottom: "15px" }}>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Verify Email
              </VuiTypography>
              <VuiTypography display="block" variant="button" color="white" my={1}>
                You will receive an e-mail in maximum 60 seconds
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography color="white">
                Verification Code
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="text"
                placeholder="Verification Code..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                  color: "white"
                })}
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}

              />
            </GradientBorder>
            <VuiBox mt={4} mb={1}>
              <VuiButton color="info" fullWidth onClick={handleVerifyEmail}>
                Verify Code for Email
              </VuiButton>
            </VuiBox>
            <VuiBox mt={4} mb={1}>
              <VuiButton color="info" fullWidth onClick={handleResendVerification}>
                Resend Code
              </VuiButton>
            </VuiBox>

          </VuiBox>


        </Card>
      </Modal>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ maxWidth: 300, margin: "auto", marginTop: "50px" }}
      ><Card >
          <VuiBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            // mx={2}
            // mt={-3}
            // py={4}
            // mb={1}
            pt={4}
            pb={3}
            px={3}
            textAlign="center"
          >
            <VuiTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </VuiTypography>
            <VuiTypography display="block" variant="button" color="white" my={1}>
              If you are in our system, you will receive an email with a verification code to reset your password. The code should arrive within a few minutes.
            </VuiTypography>
          </VuiBox>
          <VuiBox pt={4} pb={3} px={3}>
            <VuiBox component="form" role="form" onSubmit={handleReset}>
              <VuiBox mb={4}>
                <VuiInput
                  type="email"
                  label="Email"
                  value={email}
                  variant="standard"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  placeholder="john@email.com"
                />
              </VuiBox>
              <VuiBox mb={4}>

                <VuiInput
                  type="text"
                  label="Reset Code"
                  placeholder="Code"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setVerificationCode(e.target.value)}
                  InputLabelProps={{ shrink: true }}

                />
              </VuiBox>
              <VuiBox mb={4}>
                <VuiInput
                  type="password"
                  label="Enter New Password"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter New Password"
                />
              </VuiBox>
              <VuiBox mb={4}>
                <VuiInput
                  type="password"
                  label="Confirm Password"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setconfirmNewPassword(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Confirm Password"
                />
              </VuiBox>
              {!passwordsMatch && confirmNewPassword && (
                <VuiBox style={{ color: '#f542d1' }}>
                  Passwords do not match!
                </VuiBox>
              )}
              <VuiBox mt={6} mb={1}>
                <VuiButton variant="gradient" color="info" fullWidth type="submit">
                  reset
                </VuiButton>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </Card>
      </Modal>
    </CoverLayout>
  );
}

export default Login;
