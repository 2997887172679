import { useState, useEffect } from "react";

const PdfLoadingOverlay = ({ onClose }) => {
  const [dots, setDots] = useState("");

  // Simulate dot animation
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",  // Keeps it on top of other elements
        margin: "auto",
        marginLeft: "20%",
        width: "60%",
        height: "90vh",
        background: "rgba(255, 255, 255, 0.6)", // Semi-transparent (adjust for visibility)
        zIndex: 999  // Ensures it's above other content
    }}>
      {/* Close Button in Top-Left Corner */}
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10vh",
 
          left: "55vw",
          background: "rgb(225, 78, 202)",
          color: "black",
          
          borderRadius: "50%",
          width: "35px",
          height: "35px",
          fontSize: "20px",
          cursor: "pointer",
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
          zIndex: 1001, // Ensure it's above everything
          boxShadow: "0px 4px 6px rgba(0,0,0,0.2)",
        }}
      >
        ✕
      </button>

      <h2
        style={{
          color: "#e14eca",
          fontWeight: "bold",
          fontSize: "24px",
          letterSpacing: "1px",
          textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
        }}
      >
        PDF LOADING{dots}
      </h2>
    </div>
  );
};

export default PdfLoadingOverlay;
