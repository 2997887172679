import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";

import { IoFolderOutline } from "react-icons/io5";
import DraggableVaultTable from "views/components/DraggableVaultTable";
import DroppableAssistantTable from "views/components/DroppableAssistantTable";
import cleanFilename from "services/file_clean";
import { getAllFilenames, getAssistantFilenames, editAssistantVectorStore } from "services/call_backend";
import VerticalLine from "views/components/VerticalLine";
import AssistantSettingsForm from "views/components/AssistantSettingForm";
import PDFViewer from "components/PDFViewer";
import { Box, Container } from '@mui/material';
import Modal from '@mui/material/Modal';

import PdfLoadingOverlay from "views/components/PDFLoader";
import { ScaleLoader as BeatLoader } from 'react-spinners'
import Notify from "components/Notify";
import "react-notification-alert/dist/animate.css";

//const serverBaseURL = process.env.REACT_APP_BACKEND_URL;



const EditAssistant = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const location = useLocation();
    const params = location.state?.params || {};


    const [destinationData, setDestinationData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [assistantName, setAssistantName] = useState(params.assistantName || "");
    const assistantId = params.assistant_id || ""
    const [temperature, setTemperature] = useState(params.temperature || "Moderate");
    const vectorStoreId = params.vector_id || []
    const [loadingEditingData, setLoadingEditingData] = useState(false)
    
    const [viewPdfLoader, setViewPdfLoader] = useState(false)
    const [notification, setNotification] = useState({ message: "", type: "" })

    const [loading1, setLoading1] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [tableData, setTableData] = useState([])

    const [refresh, setRefresh] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const navigate = useNavigate()

    
    const handleDismiss = () => {
        setNotification({ message: "", type: "" })
    }





    async function fetchAssistantS3Filenames() {
        setLoading1(true)
        try {
            const data = await getAssistantFilenames(vectorStoreId);

            if (data) {
                const formattedData = data.map((filename) => {
                    const originalFilename = filename.document
                    const globalIndex = filename.globalIndex
                    const cleanedFilename = cleanFilename(filename.document); // Clean the filename
                    const extension = filename.document.split('.').pop().toUpperCase(); // Extract and capitalize the file extension
                    const nameWithoutExtension = cleanedFilename.replace(/\.[^/.]+$/, ''); // Remove the extension

                    // Return the formatted dictionary
                    return {
                        originalFileName: originalFilename,
                        document: nameWithoutExtension, // Cleaned filename without extension
                        type: extension, // File extension
                        view: true, // Static "view" value
                        globalIndex: globalIndex
                    };
                });
                setLoading1(false)
                setOriginalData(formattedData)
                setDestinationData(formattedData) // Return the array of dictionaries


            } else {
                console.log("Failed to fetch S3 filenames.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchAndLogAllFilenames()
        fetchAssistantS3Filenames()
        setRefresh((prev) => !prev)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        setRefresh((prev) => !prev)
    }, [tableData, destinationData]);



    const columns = [
        { label: 'DOCUMENT', field: 'document', align: 'text-left' },
        { label: 'TYPE', field: 'type', align: 'text-center' },
        { label: 'VIEW', field: 'view', align: 'text-center' }
    ];




    // Handle drag-and-drop
    const handleDragEnd = (result) => {
        const { source, destination } = result;

        // If dropped outside a droppable area
        if (!destination) return;

        // Copy data from source table to destination table
        if (source.droppableId === "source" && destination.droppableId === "destination") {
            const copiedRow = tableData[source.index];


            // Prevent duplicates
            if (!destinationData.some((row) => row.document === copiedRow.document)) {

                setDestinationData([...destinationData, copiedRow]);
            }
        }
    };

    // Remove row from destination table
    const handleRemoveRow = (index) => {
        const updatedData = [...destinationData];
        updatedData.splice(index, 1);
        setDestinationData(updatedData);
    };

    const updateAssistant = async () => {
        console.log("This is destination data ", destinationData)
        setNotification({ message: "", type: "" })
        if (destinationData.length < 1){
            setNotification({ message: "Assistants need files", type: "danger" })
            return;

        }
        setLoadingEditingData(true)
        const data = await editAssistantVectorStore(originalData, destinationData, vectorStoreId, temperature, assistantId, assistantName)

        if (data) {
            
            if (data.message) {
                setLoadingEditingData(false)
                setNotification(data)
                return   
            }
        }
        setLoadingEditingData(false)
        const params = { tab: "assistants" }
        navigate("/admin", { state: { params } })



    }
    async function fetchAndLogAllFilenames() {
        setLoading2(true)
        try {
            const data = await getAllFilenames();

            if (data) {
                const formattedData = data.map((filename) => {
                    const originalFilename = filename.document
                    const globalIndex = filename.globalIndex
                    const cleanedFilename = cleanFilename(filename.document); // Clean the filename
                    const extension = filename.document.split('.').pop().toUpperCase(); // Extract and capitalize the file extension
                    const nameWithoutExtension = cleanedFilename.replace(/\.[^/.]+$/, ''); // Remove the extension

                    // Return the formatted dictionary
                    return {
                        originalFileName: originalFilename,
                        document: nameWithoutExtension, // Cleaned filename without extension
                        type: extension, // File extension
                        view: true, // Static "view" value
                        globalIndex: globalIndex
                    };
                });

                setLoading2(false)
                setTableData(formattedData) // Return the array of dictionaries


            } else {
                console.log("Failed to fetch S3 filenames.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }




    const openModal = () => {
        setModalIsOpen(true)
    }
    const closeModal = () => {
        setModalIsOpen(false);
    };




    return (
        <>
            {loadingEditingData ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
               
                }}>
                    <BeatLoader
                        color="#e14eca"

                        size={25}
                        aria-label="Loading Spinner"
                       
                    />
                </div>
            ) : (
                <>
                    {viewPdfLoader ? (


                        <PdfLoadingOverlay onClose={closeModal}></PdfLoadingOverlay>

                    ) : (
                        <Modal
                            open={modalIsOpen}
                            onClose={closeModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                'display': 'flex',
                                'justifyContent': 'center',
                                'alignItems': 'center',
                                'position': ' fixed',
                                'top': 0,
                                'left': 0,
                                'width': '100%',
                                'height': '100%',
                                'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
                                'z-index': 1000 /* Ensure it's on top */
                            }}
                        >
                            <Box  >
                                <Container >
                                    <PDFViewer pdfUrl={pdfUrl} />
                                </Container>
                            </Box>
                        </Modal>
                    )}
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <div className="content" height="100vh">
                            <Row >
                                <Col lg="7" md="7" >
                                <Notify
                                        message={notification.message}
                                        type={notification.type}
                                        handleDismiss={handleDismiss}

                                    />
                                    <div style={{ marginLeft: "10%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
                                        <h4 className="header" style={{ fontWeight: 'bold', letterSpacing: .15, fontFamily: "Helvetica" }}><i className="tim-icons icon-settings" style={{ color: "#19AECA", marginRight: 10, marginLeft: "-10%", fontWeight: 400, fontSize: 24 }} />  Edit </h4>
                                    </div>
                                    <div className="header text-left" style={{ marginLeft: "0%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
                                        <h5 className="headers" style={{ fontWeight: 600, letterSpacing: .15, fontFamily: "Helvetica" }}><i className="tim-icons icon-settings-gear-63" style={{ color: "#525f7f", marginRight: 10, fontWeight: 400, fontSize: 18 }} />  Assistant Settings</h5>
                                    </div>

                                    <CardBody style={{ minHeight: "20vh" }}>
                                        <AssistantSettingsForm edit={true} assistantName={assistantName} setAssistantName={setAssistantName} temperature={temperature} setTemperature={setTemperature} />
                                    </CardBody>

                                    <>
                                        <div className="header text-left" style={{ marginLeft: "0%", marginBottom: 0, paddingTop: 0 }}>
                                            <h5 className="headers" style={{ fontWeight: 600, letterSpacing: .15, fontFamily: "Helvetica" }}><IoFolderOutline fontWeight={600} fontSize={18} style={{ marginRight: 10 }} /> Assistant Documents</h5>
                                        </div>
                                        <div className="header text-left" style={{ marginLeft: "0%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
                                            <p style={{ fontWeight: 400, letterSpacing: .15, fontFamily: "Helvetica", color: "#e14eca", marginLeft: "2.5%" }}>Drag and drop files from the Document Vault</p>
                                        </div>
                                        <div className="card-tasks text-left" style={{ maxHeight: "40vh" }} >
                                            <CardBody className="scroll-column" style={{ borderRadius: "5px", maxHeight: "30vh" }}>
                                                <DroppableAssistantTable columns={columns} setPdfUrl={setPdfUrl} openModal={openModal} destinationData={destinationData} setDestinationData={setDestinationData} handleRemoveRow={handleRemoveRow} loading={loading1} setViewPdfLoader={setViewPdfLoader}></DroppableAssistantTable>
                                            </CardBody>
                                        </div>
                                    </>
                                    <div style={{ marginLeft: "40%", marginTop: "2rem" }}>
                                        <Button
                                            style={{
                                                background: '#e14eca', // Matches the background color (transparent for the example)
                                                // Uses primary color for the border
                                                color: 'white', // Matches text color to primary
                                                borderWidth: '2px', // Optional: thicker border for emphasis
                                                margin: "auto",
                                                borderStyle: 'solid',
                                            }}
                                            outline // Optional: outline style for a more subtle look
                                            onClick={updateAssistant}
                                        >Edit Assistant</Button>
                                    </div>

                                </Col>
                                <Col className="text-center document-display" md="5" style={{ marginLeft: "-10px" }} >

                                    <VerticalLine color="#ccc" width="2px" ></VerticalLine>

                                    <div className="header text-left" style={{ marginLeft: "10%", marginBottom: "6%" }}>
                                        <h4 style={{ marginBottom: "-20px", marginLeft: -30, fontWeight: "bold", letterSpacing: .15, color: "334572", fontFamily: "Helvetica" }}><IoFolderOutline fontWeight={600} fontSize={24} style={{ marginRight: 10 }} />Document Vault</h4>

                                    </div>
                                    <Card className="card-tasks text-left" style={{ background: `linear-gradient(to bottom, #344675 0%, #263148 100%)`, height: "100vh", borderBottomRightRadius: "15px", marginLeft: 20 }}>
                                        <CardHeader>
                                            <Row>
                                                <Col lg="6" md="5" sm="6" Style={{ marginRight: 0, paddingRight: 0 }}>

                                                    <h6 className="d-inline " style={{ color: 'white', opacity: .9, fontSize: "14px", letterSpacing: '.11', fontWeight: 300 }}>DOCUMENT LIBRARY</h6>
                                                </Col>
                                                <Col lg="6" md="7" sm="6">
                                                    <InputGroup>
                                                        <Input placeholder="Search"
                                                            className="input-gray-placeholder input-gray-text"
                                                            style={{ paddingLeft: "10px" }}
                                                            onChange={(e) => setSearchTerm(e.target.value)}>
                                                        </Input>
                                                        <InputGroupAddon addonType="append" className="input-gray-text">
                                                            <InputGroupText>
                                                                <i className="tim-icons icon-zoom-split input-gray-text" style={{ paddingRight: 0 }}></i>
                                                            </InputGroupText>
                                                        </InputGroupAddon>

                                                    </InputGroup>
                                                </Col>
                                            </Row>

                                        </CardHeader>
                                        <CardBody className="scroll-column" style={{ borderBottomRightRadius: "50px" }}>

                                            <DraggableVaultTable key={refresh} columns={columns} setPdfUrl={setPdfUrl} openModal={openModal} data={tableData} searchTerm={searchTerm} edit={false} itemsPerPage={11} droppableId="source" loading={loading2} setViewPdfLoader={setViewPdfLoader} />
                                        </CardBody>
                                    </Card>
                                </Col>






                            </Row>
                        </div>
                    </DragDropContext>
                </>
            )}
        </>

        //   <div style={{ width: "45%" }}>
        //           <h4>Destination Table</h4>
        //           <Droppable droppableId="destination">
        //             {(provided) => (
        //               <Table bordered {...provided.droppableProps} ref={provided.innerRef}>
        //                 <thead>
        //                   <tr>
        //                     {columns.map((col, index) => (
        //                       <th key={index} className={col.align} style={{ fontWeight: 500 }}>
        //                         {col.label}
        //                       </th>
        //                     ))}
        //                     <th style={{ textAlign: "center" }}>Actions</th>
        //                   </tr>
        //                 </thead>
        //                 <tbody>
        //                   {destinationData.map((row, index) => (
        //                     <Draggable key={row.name} draggableId={row.name} index={index}>
        //                       {(provided) => (
        //                         <tr
        //                           ref={provided.innerRef}
        //                           {...provided.draggableProps}
        //                           {...provided.dragHandleProps}
        //                           style={{
        //                             ...provided.draggableProps.style,
        //                             cursor: "grab",
        //                             backgroundColor: "#e9ecef",
        //                           }}
        //                         >
        //                           {columns.map((col, colIndex) => (
        //                             <td key={colIndex} className={col.align}>
        //                               {row[col.field]}
        //                             </td>
        //                           ))}
        //                           <td style={{ textAlign: "center" }}>
        //                             <Button color="danger" onClick={() => handleRemoveRow(index)}>
        //                               <MdDelete />
        //                             </Button>
        //                           </td>
        //                         </tr>
        //                       )}
        //                     </Draggable>
        //                   ))}
        //                   {provided.placeholder}
        //                 </tbody>
        //               </Table>
        //             )}
        //           </Droppable>
        //         </div> 


    );
};

export default EditAssistant;
