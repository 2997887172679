import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";

import { Box, Container } from '@mui/material';
import Modal from '@mui/material/Modal';
import Notify from "components/Notify";
import "react-notification-alert/dist/animate.css";
import { IoFolderOutline } from "react-icons/io5";
import DraggableVaultTable from "views/components/DraggableVaultTable";
import DroppableAssistantTable from "views/components/DroppableAssistantTable";
import cleanFilename from "services/file_clean";
import { getAllFilenames, createAssistantVectorStore } from "services/call_backend";
import VerticalLine from "views/components/VerticalLine";
import AssistantSettingsForm from "views/components/AssistantSettingForm";

import PDFViewer from "components/PDFViewer";
import PdfLoadingOverlay from "views/components/PDFLoader";
import { ScaleLoader as BeatLoader } from 'react-spinners'



// const data2 = [
//     { document: 'Florida State Wheelchair', type: 'PDF', view: true },
//     { document: 'Texas State Report', type: 'DOC', view: true },
//     { document: 'California State Wheelchair', type: 'PDF', view: true },
//     { document: 'Indiana State Report', type: 'DOC', view: true },

// ];
//const serverBaseURL = process.env.REACT_APP_BACKEND_URL;

//const serverBaseURL = "https://www.projectdelphi.ai"
//const serverBaseURL = "http://localhost:8000"


const CreateAssistant = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const [destinationData, setDestinationData] = useState([]);
    const [assistantName, setAssistantName] = useState("");
    const [temperature, setTemperature] = useState("Moderate");
    const [notification, setNotification] = useState({ message: "", type: "" })
    const [tableData, setTableData] = useState([])
    const [loadingEditingData, setLoadingEditingData] = useState(false)
    const [viewPdfLoader, setViewPdfLoader] = useState(false)

    
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    // const [dots, setDots] = useState("");
    const navigate = useNavigate()




    useEffect(() => {
        fetchAndLogAllFilenames()

    }, []);

    useEffect(() => {
        setRefresh((prev) => !prev)
    }, [tableData]);

    // useEffect(() => {
    //     let interval;


    //     if (viewPdfLoader) {
    //         interval = setInterval(() => {
    //             setDots((prev) => (prev.length < 3 ? prev + "." : ""));
    //         }, 500);
    //     } else if (dots !== "") {
    //         setDots(""); // Only reset dots if they are not already empty
    //     }

    //     return () => clearInterval(interval); // Cleanup on unmount or when viewPdfLoader is false
    // }, [viewPdfLoader]); // Runs only when viewPdfLoader changes



    const columns = [
        { label: 'DOCUMENT', field: 'document', align: 'text-left' },
        { label: 'TYPE', field: 'type', align: 'text-center' },
        { label: 'VIEW', field: 'view', align: 'text-center' }
    ];

    const handleDismiss = () => {
        setNotification({ message: "", type: "" })
    }



    // Handle drag-and-drop
    const handleDragEnd = (result) => {
        const { source, destination } = result;

        // If dropped outside a droppable area
        if (!destination) return;

        // Copy data from source table to destination table
        if (source.droppableId === "source" && destination.droppableId === "destination") {
            const copiedRow = tableData[source.index];


            // Prevent duplicates
            if (!destinationData.some((row) => row.document === copiedRow.document)) {

                setDestinationData([...destinationData, copiedRow]);
            }
        }
    };

    // Remove row from destination table
    const handleRemoveRow = (index) => {
        const updatedData = [...destinationData];
        updatedData.splice(index, 1);
        setDestinationData(updatedData);
    };

    const createAssistant = async () => {

        
        setNotification({ message: "", type: "" })
        if (assistantName === "") {
            setNotification({ message: "Assistants need names", type: "danger" })
            return;
        }
        if (destinationData.length < 1){
            setNotification({ message: "Assistants need files", type: "danger" })
            return;

        }
        setLoadingEditingData(true)

        const files = destinationData.map(x => x.originalFileName);

        const data = await createAssistantVectorStore(files, assistantName, temperature)
        if (data.task_id ==="health"){
            setNotification({ message: data.vector_id, type: "danger" })
            return;

        }

        setLoadingEditingData(false)
        const params = { tab: "assistants" }

        navigate("/admin", { state: { params } })

        // setVectorStoreId



    }

    async function fetchAndLogAllFilenames() {

        setLoading(true)
        try {
            const data = await getAllFilenames();

            if (data) {
                const formattedData = data.map((filename) => {
                    const originalFilename = filename.document
                    const globalIndex = filename.globalIndex
                    const cleanedFilename = cleanFilename(filename.document); // Clean the filename
                    const extension = filename.document.split('.').pop().toUpperCase(); // Extract and capitalize the file extension
                    const nameWithoutExtension = cleanedFilename.replace(/\.[^/.]+$/, ''); // Remove the extension

                    // Return the formatted dictionary
                    return {
                        originalFileName: originalFilename,
                        document: nameWithoutExtension, // Cleaned filename without extension
                        type: extension, // File extension
                        view: true, // Static "view" value
                        globalIndex: globalIndex
                    };
                });
                console.log(formattedData)

                setTableData(formattedData) // Return the array of dictionaries
                setLoading(false)


            } else {
                console.log("Failed to fetch S3 filenames.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }


    // const fetchData = async () => {
    //     setIsLoggedIn(false)
    //     setLoading(true)

    //     try {

    //         fetchAndLogAllFilenames()
    //         setRefresh(!refresh)

    //     } catch (error) {
    //         console.error("Error during parallel fetching:", error);
    //     }
    // };


    const openModal = () => {
        setModalIsOpen(true)
    }
    const closeModal = () => {
        setModalIsOpen(false);
    };


    return (
        <>
            {loadingEditingData ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
               
                }}>
                    <BeatLoader
                        color="#e14eca"

                        size={25}
                        aria-label="Loading Spinner"
                      
                    />
                </div>
            ) : (
                <>
                    {viewPdfLoader ? (


                        <PdfLoadingOverlay onClose={closeModal}></PdfLoadingOverlay>

                    ) : (
                        <Modal
                            open={modalIsOpen}
                            onClose={closeModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                'display': 'flex',
                                'justifyContent': 'center',
                                'alignItems': 'center',
                                'position': ' fixed',
                                'top': 0,
                                'left': 0,
                                'width': '100%',
                                'height': '100%',
                                'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
                                'z-index': 1000 /* Ensure it's on top */
                            }}
                        >
                            <Box  >
                                <Container >
                                    <PDFViewer pdfUrl={pdfUrl} />
                                </Container>
                            </Box>
                        </Modal>
                    )}
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <div className="content" height="100vh">
                            <Row >
                                <Col lg="7" md="7" >
                                    <Notify
                                        message={notification.message}
                                        type={notification.type}
                                        handleDismiss={handleDismiss}

                                    />
                                    <div style={{ marginLeft: "10%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
                                        <h4 className="header" style={{ fontWeight: 'bold', letterSpacing: .15, fontFamily: "Helvetica" }}><i className="tim-icons icon-settings" style={{ color: "#19AECA", marginRight: 10, marginLeft: "-10%", fontWeight: 400, fontSize: 24 }} />Assistant Creator</h4>
                                    </div>
                                    <div className="header text-left" style={{ marginLeft: "0%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
                                        <h5 className="headers" style={{ fontWeight: 600, letterSpacing: .15, fontFamily: "Helvetica" }}><i className="tim-icons icon-settings-gear-63" style={{ color: "#525f7f", marginRight: 10, fontWeight: 400, fontSize: 18 }} />  Assistant Settings</h5>
                                    </div>

                                    <CardBody style={{ minHeight: "20vh" }}>
                                        <AssistantSettingsForm assistantName={assistantName} setAssistantName={setAssistantName} temperature={temperature} setTemperature={setTemperature} />
                                    </CardBody>

                                    <>
                                        <div className="header text-left" style={{ marginLeft: "0%", marginBottom: 0, paddingTop: 0 }}>
                                            <h5 className="headers" style={{ fontWeight: 600, letterSpacing: .15, fontFamily: "Helvetica" }}><IoFolderOutline fontWeight={600} fontSize={18} style={{ marginRight: 10 }} /> Assistant Documents</h5>
                                        </div>
                                        <div className="header text-left" style={{ marginLeft: "0%", marginBottom: 0, marginTop: 0, paddingTop: 0 }}>
                                            <p style={{ fontWeight: 400, letterSpacing: .15, fontFamily: "Helvetica", color: "#e14eca", marginLeft: "2.5%" }}>Drag and drop files from the Document Vault</p>
                                        </div>
                                        <div className="card-tasks text-left" style={{ maxHeight: "40vh" }} >
                                            <CardBody className="scroll-column" style={{ borderRadius: "5px", maxHeight: "30vh" }}>
                                                <DroppableAssistantTable columns={columns} setPdfUrl={setPdfUrl} openModal={openModal} destinationData={destinationData} setDestinationData={setDestinationData} handleRemoveRow={handleRemoveRow} setViewPdfLoader={setViewPdfLoader}></DroppableAssistantTable>
                                            </CardBody>
                                        </div>
                                    </>
                                    <div style={{ marginLeft: "40%" }}>
                                        <Button
                                            style={{
                                                background: '#e14eca', // Matches the background color (transparent for the example)
                                                // Uses primary color for the border
                                                color: 'white', // Matches text color to primary
                                                borderWidth: '2px', // Optional: thicker border for emphasis
                                                margin: "auto",
                                                borderStyle: 'solid',
                                            }}
                                            outline // Optional: outline style for a more subtle look
                                            onClick={createAssistant}
                                        >Create</Button>
                                    </div>

                                </Col>
                                <Col className="text-center document-display" md="5" style={{ marginLeft: "-10px" }} >

                                    <VerticalLine color="#ccc" width="2px" ></VerticalLine>

                                    <div className="header text-left" style={{ marginLeft: "10%", marginBottom: "6%" }}>
                                        <h4 style={{ marginBottom: "-20px", marginLeft: -30, fontWeight: "bold", letterSpacing: .15, color: "334572", fontFamily: "Helvetica" }}><IoFolderOutline fontWeight={600} fontSize={24} style={{ marginRight: 10 }} />Document Vault</h4>

                                    </div>
                                    <Card className="card-tasks text-left" style={{ background: `linear-gradient(to bottom, #344675 0%, #263148 100%)`, height: "75vh", borderBottomRightRadius: "15px", marginLeft: 20 }}>
                                        <CardHeader>
                                            <Row>
                                                <Col lg="6" md="5" sm="6" Style={{ marginRight: 0, paddingRight: 0 }}>

                                                    <h6 className="d-inline " style={{ color: 'white', opacity: .9, fontSize: "14px", letterSpacing: '.11', fontWeight: 300 }}>DOCUMENT LIBRARY</h6>
                                                </Col>
                                                <Col lg="6" md="7" sm="6">
                                                    <InputGroup>
                                                        <Input placeholder="Search"
                                                            className="input-gray-placeholder input-gray-text"
                                                            style={{ paddingLeft: "10px" }}
                                                            onChange={(e) => setSearchTerm(e.target.value)}>
                                                        </Input>
                                                        <InputGroupAddon addonType="append" className="input-gray-text">
                                                            <InputGroupText>
                                                                <i className="tim-icons icon-zoom-split input-gray-text" style={{ paddingRight: 0 }}></i>
                                                            </InputGroupText>
                                                        </InputGroupAddon>

                                                    </InputGroup>
                                                </Col>
                                            </Row>

                                        </CardHeader>
                                        <CardBody className="scroll-column" style={{ borderBottomRightRadius: "50px" }}>

                                            <DraggableVaultTable key={refresh} setPdfUrl={setPdfUrl} openModal={openModal} loading={loading} columns={columns} data={tableData} searchTerm={searchTerm} edit={false} itemsPerPage={11} droppableId="source" setViewPdfLoader={setViewPdfLoader} />
                                        </CardBody>
                                    </Card>
                                </Col>






                            </Row>
                        </div>
                    </DragDropContext>
                </>
            )}
        </>




    );
};

export default CreateAssistant;
