import { React, useState, useEffect } from 'react';
import { Table, Button, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { viewPdf } from 'services/call_backend';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { ScaleLoader as BeatLoader  } from "react-spinners";

const DocumentFilesTable = ({ loading, columns, data, setPdfUrl, openModal, searchTerm, setViewPdfLoader,  vaultName="trail-uploads", edit = false,itemsPerPage=8 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState(data || [])
  const [abortController, setAbortController] = useState(null);


  useEffect(() => {
    setTableData(data || []); // Ensure tableData is always an array
    setCurrentPage(1)
  }, [data]);

    // Filter data based on the search term
    const filteredData = searchTerm
    ? tableData.filter(row =>
        columns.some(col => {
          const cellValue = row[col.field];
          // Only perform search if cellValue is defined and not null
          return cellValue != null && cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
        })
      )
    : tableData;

     
   
     const handleViewPDF = async (vaultName, filename) => {
      setViewPdfLoader(true)
       try {
           // Abort any existing request before making a new one
         if (abortController) {
             abortController.abort();
         }
     
         const newController = new AbortController();
         setAbortController(newController);
   
           const pdfUrl = await viewPdf(vaultName, filename, newController.signal);
           setPdfUrl(pdfUrl);
   
           if (pdfUrl) {
            setViewPdfLoader(false)
               openModal();
           }
       } catch (error) {
           if (error.name === 'AbortError') {
               console.warn('Fetch aborted:', error);
           } else {
               console.error('Error fetching data:', error);
           }
       }
   };

  // Calculate total pages based on the filtered data length
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the starting and ending index of the data to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);



  // Handle page change
  const goToPage = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div>
             { loading ? (
                
                <>
                  <BeatLoader
                  color="#e14eca"
                  loading={loading}
                  size={25}
                  aria-label="Loading Spinner"
                  style={{marginLeft:"43%"}}
                  ></BeatLoader>
                  </>
                    
                ):(
                  <>
    <Table style={{ borderBottomRightRadius: '50px' }} className="small" >
      <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index} className={col.align || 'text-left'} style={col.label === 'TYPE' || col.label === 'FILE SIZE'|| col.label === 'VIEW' ? { color: '#1E92B6', fontWeight:300 }: {fontWeight:300}}>
              {col.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
      {currentData.length > 0 ? (
        currentData.map((row, rowIndex) => (

          <tr key={rowIndex}>
            {columns.map((col, colIndex) => (
              <td key={colIndex} className={col.align || 'text-left'} >
                {col.field === 'view' ? (
                  <Button color="link" id={`tooltip_${rowIndex}`} title="" type="button" onClick={() => handleViewPDF(vaultName, row['originalFileName'])}>
                    <MdOutlineRemoveRedEye style={{ color: '#1E92B6' }} />
                    <UncontrolledTooltip delay={0} target={`tooltip_${rowIndex}`}>
                      View
                    </UncontrolledTooltip>
                  </Button>
                ) : (
                  <p className="text" style={col.field === 'type' || col.field === 'size' ? { color: '#1E92B6' , fontWeight:400} : { fontWeight:400}}>
                    {row[col.field]}
                  </p>
                )}
              </td>
            ))}
          </tr>
        ))
    
      ):(
        <td colSpan={columns.length} style={{ textAlign: 'center', color: '#1E92B6' }}>
        No data available
      </td>
      )}
        </tbody>
    </Table>
                
     
<Pagination style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: 13 }} className='table-pagination'>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink style={{ color: "white", opacity: .8 }} previous onClick={() => goToPage(currentPage - 1)} className='table-pagination'>
            Prev
          </PaginationLink>
        </PaginationItem>
        {pageNumbers
    .filter((page) => {
      // Show a maximum of 5 pages, centered around the current page
      const maxVisiblePages = 3;
      const halfRange = Math.floor(maxVisiblePages / 2);

      if (currentPage <= halfRange) {
        // Near the beginning of the pagination
        return page <= maxVisiblePages;
      } else if (currentPage > totalPages - halfRange) {
        // Near the end of the pagination
        return page > totalPages - maxVisiblePages;
      } else {
        // In the middle of the pagination
        return page >= currentPage - halfRange && page <= currentPage + halfRange;
      }
    })
    .map((page) => (
      <PaginationItem key={page} active={page === currentPage}>
        <PaginationLink
          onClick={() => goToPage(page)}
          style={{ color: 'white', opacity: 0.8 }}
          className='table-pagination'
    
        >
          {page}
        </PaginationLink>
      </PaginationItem>
    ))}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink next onClick={() => goToPage(currentPage + 1)} style={{ color: "white", opacity: .8 }} className='table-pagination'>
            Next
          </PaginationLink>
        </PaginationItem>
      </Pagination>
      </>
   )} 
 </div>

  );
};

export default DocumentFilesTable;