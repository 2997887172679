import React from "react";
import { Form, FormGroup, Label, Input, Col, Row } from "reactstrap";

import "react-color-palette/css";

const AssistantSettingsForm = ({assistantName, setAssistantName, temperature, setTemperature, edit=false}) => {

 
  return (
    <Form style={{ fontFamily: "Arial, sans-serif", fontSize: "12px", color: "#333" }}>
      <Row>
        {/* Assistant Name */}
        <Col md={6}>
          <FormGroup>
            <Label className="assistant" for="assistantName">Assistant Name:</Label>
            {edit ? (
                      <Input
                      type="text"
                      id="assistantName"
                      value={assistantName}

                      style={{
                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        padding: "6px 10px",
                       
                      }}
                    />

                      ):(
                        <Input
                        type="text"
                        id="assistantName"
                        value={assistantName}
                        onChange={(e) => setAssistantName(e.target.value)}
                        placeholder="Enter name"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid #ccc",
                          padding: "6px 10px",
                        }}
                      />
                      )}
                        

          </FormGroup>

          {/* Assistant Icon */}
          <FormGroup>
            {/* <Label className="assistant" for="assistantIcon">Assistant Icon:</Label>
            <Input
              type="file"
              id="assistantIcon"
              style={{
                borderRadius: "10px",
                padding: "6px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            /> */}
          </FormGroup>

          {/* Assistant Color */}
          <FormGroup>
            {/* <Label className="assistant" for="assistantColor">Assistant Color:</Label>

            <Input
              type="color"
              id="assistantColor"
              style={{
                width: "40px",
                height: "40px",
                
              }}
            /> */}
          </FormGroup>
        </Col>

        {/* Temperature */}
        <Col md={6}>
          <FormGroup className="assistant">
            <Label className="assistant" >Temperature:</Label>
            <div style={{ display: "flex", flexDirection: "column" , marginLeft:20 }}>
              <Label className="assistant" check style={{ marginBottom: "5px"}}>
                <Input
                  type="radio"
                  name="temperature"
                  value="Exact"
                  color="gray-dark"
                  checked={temperature||"Moderate"}
                  onChange={(e) => setTemperature(e.target.value)}
                />
                Exact
              </Label>
              <Label className="assistant" check style={{ marginBottom: "5px" }}>
                <Input
                  type="radio"
                  name="temperature"
                  value="Moderate"
                  checked={temperature === "Moderate"}
                  onChange={(e) => setTemperature(e.target.value)}
                />
                Moderate
              </Label>
              <Label check className="assistant">
                <Input
                  type="radio"
                  name="temperature"
                  value="Flexible"
                  checked={temperature === "Flexible"}
                  onChange={(e) => setTemperature(e.target.value)}
                />
                Flexible
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default AssistantSettingsForm;
