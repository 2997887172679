
import { React, useState, useEffect } from "react"
import { useLocation } from "react-router-dom";




// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Form,
    Input,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Row,
    Col,
    
  } from "reactstrap";

  import UploadMonitoringTable from "views/components/UploadsMonitoringTable";
  import AssistantMonitoringTable from "views/components/AssistantMonitoringTable";
  import {fetchUploadTaskStatuses,  getCreateUpdateAssistantsStatus} from "services/call_backend";



  // const data2 = [{id:"uuid1", name:"MyPolicyDocs",date:"1/23/2025 04:36:00 PM", status:"IN-PROGRESS", progress:.50, errors:[]},
  //     {id:"uuid2",name:"Employeee Policies",date:"1/14/2025 04:36:00 PM", status:"FAILED", progress:.50, errors: [{file:"Study1.pdf",error:"not uploaded"}, {file:"Study2.pdf", error:"not parsable"}]},
  //     {id:"uuid3",name:"Survey Study",date:"1/23/2025 04:36:00 PM", status:"SUCCESS", progress:100, errors: []},
  //     {id:"uuid4",name:"Employee Policies",date:"1/16/2025 04:36:00 PM", status:"SUCCESS", progress:100, errors:[]},]

  const Account = () =>{
    const location = useLocation();
    const params = location.state?.params || {};
    const [horizontalTabs, sethorizontalTabs] = useState(params.tab || "profile");
   
    const [loading, setLoading]=useState(true)
    const [refresh,setRefresh]=useState(true)
    const [uploadData, setUploadData] = useState([])
    const [assistantData, setAssistantData] = useState([])
    const [isPolling, setIsPolling] = useState(false)
    const [openedCollapseOne, setopenedCollapseOne] = useState(true);
    const [openedCollapseTwo, setopenedCollapseTwo] = useState(false);
    const [openedCollapseThree, setopenedCollapseThree] = useState(false);
    // with this function we change the active tab for all the tabs in this page
    const changeActiveTab = (e, tabState, tabName) => {
      e.preventDefault();
      switch (tabState) {
        case "horizontalTabs":
          sethorizontalTabs(tabName);
          break;
        // case "verticalTabsIcons":
        //   setverticalTabsIcons(tabName);
        //   break;
        // case "pageTabs":
        //   setpageTabs(tabName);
        //   break;
        // case "verticalTabs":
        //   setverticalTabs(tabName);
        //   break;
        default:
          break;
      }
    };

    const getDataUploads = async()=>{
      
      const uploadTableData = await fetchUploadTaskStatuses()
      console.log("This is the uploadTableData")
      if (uploadTableData != null){
        setIsPolling(true)
      }
      
      setUploadData(uploadTableData) 
      // setUploadData(data2) 
   
    }

    const getAssistantTableData = async() =>{
      const data = await getCreateUpdateAssistantsStatus()
      setLoading(false)
      setAssistantData(data)

    }

   // I want to update a table in a react up. The return value is a list of dictionaries. One of the dictionaries returns is a "status" field. How do I get it to call the function repeatedly untill all status are either "SUCCESS" or "FAILURE"


    useEffect(() => {
      const fetchData = async () => {
        try {
          // Run both async functions in parallel
          await Promise.all([getAssistantTableData(), getDataUploads()]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
      fetchData();
    }, []);


    const pollAllStatuses = async () => {
      // Poll upload statuses
      try {
      const statuses = await fetchUploadTaskStatuses();
      console.log("statuses are \n", statuses)
      setUploadData(Array.isArray(statuses) ? statuses : []); 

      // Poll assistant table data
      const assistantData = await getCreateUpdateAssistantsStatus();
      setAssistantData(Array.isArray(assistantData) ? assistantData : []);
    
      // Stop polling if everything is SUCCESS or FAILURE
      // Stop polling if everything is SUCCESS or FAILURE
      let allUploadsDone = uploadData.length === 0 || uploadData.every(row => ["SUCCESS", "FAILURE"].includes(row.status));
      let allAssistantsDone = assistantData.length === 0 || assistantData.every(row => ["SUCCESS", "FAILURE"].includes(row.status))
      
      
    
      if (allUploadsDone && allAssistantsDone) {
        setIsPolling(false); // Stop polling if everything is done
      }
    }
    catch (error){
      console.error("Error polling statuses:", error);
    }
    };
    
    // eslint-disable-next-line 
    useEffect(() => {
      if (isPolling) {
        const interval = setInterval(async () => {
          await pollAllStatuses();
        }, 10000); // Poll every 1 second
    
        return () => clearInterval(interval); // Cleanup interval on unmount
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPolling, uploadData]);






   

    useEffect(() => {
      setRefresh((prev) => !prev)
    }, [uploadData]);



    return(
        <>
 
      <div className="content">
        <Row>
          <Col md="11"style={{margin:"auto"}}>
            <Card>
              <CardHeader>
                
                <CardTitle tag="h3" style={{marginTop:"2rem"}}>Account Settings and Activity</CardTitle>
              </CardHeader>
              <CardBody>
                <Nav className="nav-pills-info" pills>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={horizontalTabs === "profile" ? "active" : ""}
                      onClick={(e) =>
                        changeActiveTab(e, "horizontalTabs", "profile")
                      }
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={horizontalTabs === "uploads" ? "active" : ""}
                      onClick={(e) =>
                        changeActiveTab(e, "horizontalTabs", "uploads")
                      }
                    >
                      Uploads
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={horizontalTabs === "assistants" ? "active" : ""}
                      onClick={(e) =>
                        changeActiveTab(e, "horizontalTabs", "assistants")
                      }
                    >
                      Assistant
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="tab-space" activeTab={horizontalTabs}>
                  <TabPane tabId="profile">

              <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
                style={{width:"75%"}}
              >
                <Card className="card-plain" >
                  <CardHeader role="tab">
                    <a
                      aria-expanded={openedCollapseOne}
                      href="#pablo"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        setopenedCollapseOne(!openedCollapseOne);
                      }}
                    >
                      Basic Information{" "}
                      <i className="tim-icons icon-minimal-down" />
                    </a>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={openedCollapseOne}>
                    <CardBody>
                        <Row>
                            <Col md="6">
                                <label>First Name</label>
                                <Input type="text" />

                            </Col>
                            <Col md="6">
                                <label>Last Name</label>
                                <Input type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="9">
                                <label>Email</label>
                                <Input type="email" />
                            </Col>
                            <Col md="3">
                            </Col>
                        </Row>
                        <hr></hr>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="card-plain">
                  <CardHeader role="tab">
                    <a
                      aria-expanded={openedCollapseTwo}
                      href="#pablo"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        setopenedCollapseTwo(!openedCollapseTwo);
                      }}
                    >
                      Change Password{" "}
                      <i className="tim-icons icon-minimal-down" />
                    </a>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={openedCollapseTwo}>
                    <CardBody>
                      <Row>
                        <Col md="6" sm="6">
                        <Form>
                            <Row>
                                <Col>
                                <label>Password</label>
                                <Input type="text" 
                                       placeholder="Current password"
                                      
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <label>New Password</label>
                                <Input type="password" 
                                       placeholder="New password"
                                /></Col>
                            </Row>
                            <Row>
                                <Col>
                                <label>Confirm New Password</label>
                                <Input type="password" 
                                       placeholder="New password"
                                /></Col>
                            </Row>
                            <Row>
                                <Col>
                                <Button>Update Password</Button>
                                </Col>
                            </Row>
                        </Form>
                        
                        </Col>
                        <Col md = "5" sm="6">
                        <Card style={{width:"75%" , float: "right" , marginTop:"1rem"}}>
                        <h4 >Password Requirements</h4>
                        <h6 >Password Requirements</h6>
                        <br></br>
                        <ul style={{fontSize:"12px", float:"left", marginLeft:"-1.75rem", fontWeight:200}}>
                            <li>
                                Minimun length of 8 characters
                            </li>
                            <li>
                                At least one number
                            </li>
                            <li>
                                At least one special character
                            </li>
                        </ul>
                        </Card>
                        </Col>
                      </Row>
                      <hr></hr>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="card-plain">
                  <CardHeader role="tab">
                    <a
                      aria-expanded={openedCollapseThree}
                      href="#pablo"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        setopenedCollapseThree(!openedCollapseThree);
                      }}
                    >
                      Two-Factor Authentication{" "}
                      <i className="tim-icons icon-minimal-down" />
                    </a>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={openedCollapseThree}>
                    <CardBody>
                      <Row style={{marginBottom:"1rem"}}>
                        <Col md="6">
                        <label>SMS Number</label>
                        <Input type="phone" 
                                placeholder="+1 (555) 872-0725"
                        />
                        </Col>
                        <Col md = "5" sm="6">
                        <Card style={{width:"75%" , float: "right" , marginTop:"1rem"}}>
                        <Button>Edit</Button>
                        </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                        <label>Authenticator App</label>
                        <Input type="password" 
                                placeholder="New password"
                        />
                        </Col>
                        <Col md = "5" sm="6">
                        <Card style={{width:"75%" , float: "right" , marginTop:"1rem"}}>
                        <Button>Edit</Button>
                        </Card>
                        </Col>
                        
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
        
                  </TabPane>
                  <TabPane tabId="uploads">
                      <Card>
                      <CardBody>
                        <UploadMonitoringTable key={refresh} data={uploadData}></UploadMonitoringTable>
                      </CardBody>

                      </Card>
                  </TabPane>
                  <TabPane tabId="assistants">
                  <Card>
                      <CardBody>
                        <AssistantMonitoringTable key={refresh} data={assistantData} loading={loading}></AssistantMonitoringTable>
                      </CardBody>

                      </Card>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
         
        </Row>
 

      </div>


</>)
  }

export default Account