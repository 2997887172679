
import CreateAssistant from "views/pages/assistants/CreateAssistant";
import EditAssistant from "views/pages/assistants/EditAssistant";


const routes = [
  {
    path: "/assistant-creator",
    name: "ASSISTANT CREATOR",

    icon: "tim-icons icon-chart-pie-36",
    component: <CreateAssistant />,
    layout: "/admin",
  },
    {
      path: "/assistant-editor",
      name: "ASSISTANT EDITOR",
  
      icon: "tim-icons icon-components",
      component: <EditAssistant />,
      layout: "/admin",
    },
    



];
export default routes