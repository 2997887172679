function cleanFilename(filename) {
    try {
        // Decode URL-encoded characters
        let decodedFilename = decodeURIComponent(filename);

        // Split the filename into name and extension
        let parts = decodedFilename.split('.');
        let namePart = parts.slice(0, -1).join('.'); // Handles cases with multiple dots
        let extension = parts.slice(-1)[0]; // File extension

        // Remove underscores, replace multiple spaces with a single space, and trim
        namePart = namePart.replace(/_/g, ' ').replace(/\s+/g, ' ').trim();

        // Reconstruct the filename
        let cleanedFilename = extension ? `${namePart}.${extension}` : namePart;

        return cleanedFilename;
    } catch (error) {
        console.error(`Error cleaning filename: ${error}`);
        return filename;
    }
}

export default cleanFilename;