import React , {useState} from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Table, Button, UncontrolledTooltip } from "reactstrap";
import { MdOutlineRemoveRedEye, MdClose } from "react-icons/md";
import { ScaleLoader as BeatLoader  } from "react-spinners";
import { viewPdf } from "services/call_backend";

//   const data2 = [
//     { document: 'Florida State Wheelchair', type: 'PDF', view: true },
//     { document: 'Texas State Report', type: 'DOC', view: true },
//     { document: 'California State Wheelchair', type: 'PDF', view: true },
//     { document: 'Indiana State Report', type: 'DOC', view: true },

// ];

const DroppableAssistantTable = ({destinationData, handleRemoveRow, setPdfUrl, openModal, setViewPdfLoader, loading=false, columns=[], edit=true, droppableId="destination", borderColor = "rgba(255,255,255,.2)" }) => {
  // const [tableData, setTableData] = useState(data); // Main dataset
  // const [currentPage, setCurrentPage] = useState(1);
  const vaultName = "trail-uploads"
  const [abortController, setAbortController] = useState(null);

  const handleViewPDF = async (vaultName, filename) => {
    try {
        // Abort any existing request before making a new one
      if (abortController) {
          abortController.abort();
      }
      setViewPdfLoader(true)
      const newController = new AbortController();
      setAbortController(newController);

        const pdfUrl = await viewPdf(vaultName, filename, newController.signal);
        setPdfUrl(pdfUrl);
        setViewPdfLoader(false)
        if (pdfUrl) {
          
            openModal();
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn('Fetch aborted:', error);
        } else {
            console.error('Error fetching data:', error);
        }
    }
};
 
  return (
    <div>
    {loading ?(
        <BeatLoader
        color="#e14eca"
        loading={loading}
        size={15}
        aria-label="Loading Spinner"
        style={{marginLeft:"45%"}}
        ></BeatLoader>
      ):(
        <>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>

            <Table style={{ borderBottomRightRadius: '50px' }} className='small' >
              <thead>
                <tr>
                  {columns.map((col, index) => (
                    <th key={index} className={col.align || 'text-left'} style={col.label === 'TYPE' || col.label === 'FILE SIZE' || col.label === 'VIEW' ? { color: '#1E92B6', fontWeight: 300 } : { fontWeight: 300 }}>
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {destinationData.length > 0 ? (
                  destinationData.map((row, rowIndex) => (
                    <Draggable key={`${row.document}-${rowIndex}`} draggableId={`${row.document}-${rowIndex}`} index={rowIndex}>
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                 
                          borderBottom: `2px solid ${borderColor || 'white'}`,
                          ...provided.draggableProps.style,
                          cursor: "grab",
                         
                        }}
                      >

                      {columns.map((col, colIndex) => (
                        <td key={colIndex} className={` ${col.align || 'text-left'}`} >
                          {col.field === 'view' ? (
                            <Button color="link" id={`tooltip_${rowIndex}`} title="" type="button" onClick={() => handleViewPDF(vaultName, row['originalFileName'])}>
                              <MdOutlineRemoveRedEye style={{ color: '#1E92B6' }} />
                              <UncontrolledTooltip delay={0} target={`tooltip_${rowIndex}`}>
                                View
                              </UncontrolledTooltip>
                            </Button>
                          ) : (
                            <p className="text" style={col.field === 'type' || col.field === 'size' ? { color: '#1E92B6', fontWeight: 300 } : { fontWeight: 300 }}>
                              {row[col.field]}
                            </p>
                          )}
                        </td>
                      ))}
                      {edit && (<td style={{ textAlign: 'right' }}>
                          {/* "X" icon to remove row */}
                          <Button color="link" onClick={() => handleRemoveRow(rowIndex)}>
                            <MdClose style={{ color: 'red', fontSize: '1.2em', opacity:.8 }} />
                          </Button>
                        </td>)}
                    </tr>
                  )}
                    </Draggable>
                  ))

                ) : (
            
                  <td colSpan={columns.length} style={{ textAlign: 'center', color: '#1E92B6' }}>
                    No data available
                  </td>
                )}
               


                {provided.placeholder}
              </tbody>
            </Table>
          </div>
        )}
      </Droppable>
      </>
      )}
    </div>

  );
};

export default DroppableAssistantTable;
