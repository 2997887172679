import React from "react";
import { useNavigate } from "react-router-dom";

const ServerDown = () => {
  const navigate = useNavigate();

  return (
    <div className="server-down-page">
      <h1>🚨 Server Unavailable 🚨</h1>
      <p>We are experiencing issues connecting to our servers. Please try again later.</p>
      <button onClick={() => navigate("/auth/login")}>Go Back to Home</button>
    </div>
  );
};

export default ServerDown;
