import React, { useState , useEffect} from "react";
// Reuse the ProgressBar component from earlier
import { Table,  Collapse } from 'reactstrap';
import { ScaleLoader as BeatLoader} from "react-spinners"




const UploadMonitoringTable = ({data, loading=false}) => {
  const [tableData, setTableData] = useState(data || [])


  useEffect(() => {
    setTableData(data || []); // Ensure tableData is always an array
  }, [data]);


 const [expandedRows, setExpandedRows] = useState({}); // Object to track which rows are expanded

 const getStatusIcon = (status) =>{
 
    if(["PROGRESS", "STARTED"].includes(status)) {
        return "tim-icons icon-refresh-02"
    }
    else if(status === "SUCCESS"){
        return "tim-icons icon-check-2"
    }
    else if (status ==="FAILURE"){
        return "tim-icons icon-alert-circle-exc"
    }
    else{
      return "tim-icons icon-refresh-02"
    }
    }

    const getRowColor = (status)=>{
      
        if(["PROGRESS", "STARTED"].includes(status)){
            return "#19AECA"
        }
        else if (status === "SUCCESS"){
            return "green"
        }
        else if (status ==="FAILURE"){
            return "red"
        }
        else {
          return "#19AECA"
        }
      }

 const toggleDetails = (rowIndex) => {
   setExpandedRows((prev) => ({
     ...prev,
     [rowIndex]: !prev[rowIndex], // Toggle the specific row's collapse
   }));
 };

  return (
    <div style={{ width: "100%", border: "1px solid #ddd", borderRadius: "5px" }}>
      {loading ?(
                <BeatLoader
                color="#e14eca"
                loading={loading}
                size={15}
                aria-label="Loading Spinner"
                style={{marginLeft:"80%"}}
                ></BeatLoader>
              ):(
                <>
      <Table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr >
          <th style={{ padding: "10px", textAlign: "left" }}>{""}</th>
            <th style={{ padding: "10px", textAlign: "left" }}>Upload Name</th>
            <th style={{ padding: "10px", textAlign: "left" }}>Upload Date</th>
            <th style={{ padding: "10px", textAlign: "left" }}>Upload Progress</th>
          </tr>
        </thead>
        <tbody>
            {tableData && tableData.length > 0 ? (
          tableData.map((row, index) => (
            <React.Fragment key={index}>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
              <td style={{ textAlign: "left", verticalAlign: "middle" }}>
                <i
                  className={getStatusIcon(row.status)}
                  style={{ color: getRowColor(row.status), marginRight: 10, fontWeight: 600, fontSize: 16 }}
                />
              </td>
                <td style={{ padding: "10px" }}>{row.name}</td>
                <td style={{ padding: "10px" }}>{row.date}</td>
                <td style={{ padding: "10px" }}>
                 <progress value={row.progress}/>
                </td>
              </tr>
              { row.errors.length > 0 && (
                <tr>
                    <td>{"  "}</td>
                  <td colSpan={12}>
                    <div>
                    <a
                      aria-expanded={!!expandedRows[index]}
                      href="#pablo"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      
                      onClick={(e) => {
                        e.preventDefault();
                        toggleDetails(index);
                      }}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color:"#e14eca"
                        
                      }}
                    >
                      Details{" "}
                      <i
                            className={`tim-icons ${
                              expandedRows[index] ? "icon-minimal-up" : "icon-minimal-down"
                            }`}
                          />
                    </a>
                    </div>
                    <Collapse role="tabpanel" isOpen={!!expandedRows[index]}>
                    {row.errors.map((error, idx) => (
                          <tr key={idx} style={{  }}>
                           
                            <td>{" "}</td>
                            <td>{error.file}</td> 
                            <td>{" "}</td>
                            <td>{" "}</td>
                            <td>{error.error}</td>
                          </tr>
                        ))}
                   
                      </Collapse>
                    </td>
                    </tr>

                   
                      

                    
                 
              )}
            </React.Fragment>
          ))
        ):(
            <></>

        )}
        </tbody>
      </Table>
    </>
              )}
    </div>
  );
};

export default UploadMonitoringTable;
