// /*!

// =========================================================
// * Black Dashboard PRO React - v1.2.4
// =========================================================

// * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
// * Copyright 2024 Creative Tim (https://www.creative-tim.com)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// /** 

// =========================================================
// * Vision UI PRO React - v1.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
// * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

// * Design and Coded by Simmmple & Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

// */

import { useState, useEffect, useRef } from "react";
import { useAuth } from "./AuthContext";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";

// @mui material components

import Card from "@mui/material/Card";
import { Modal} from '@mui/material';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";


// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/img/knowli-site-image/Background.png";
import smImage from "assets/img/knowli-site-image/Knowli AI Logo.svg"

function SignUp() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [openEmailModal, setOpenEmailModal] = useState(false); // Modal for Email Verification
  const [verificationCode, setVerificationCode] = useState('');
  const notificationAlertRef = useRef()
  const navigate = useNavigate()

  const { signUp, verifyEmail, resendConfirmationCode, signInAfterVerification } = useAuth()


  const handleOpenEmailModal = () => setOpenEmailModal(true);
  const handleCloseEmailModal = () => setOpenEmailModal(false);


  const notify = (message =null, type="info", fullMessage = null) => {
    let options;
    if (fullMessage){
      options = {
        place: "tr",
        message: (
          fullMessage
        ),
        type: type, // "success", "danger", "info", "warning"
        icon: "tim-icons icon-bell-55",
        closeButton: true,
        autoDismiss: 5 
  
      };
    }
    else{
    options = {
      place: "tr",
      message: (
        <div>
          {message}{" "}
        </div>
      ),
      type: type, // "success", "danger", "info", "warning"
      icon: "tim-icons icon-bell-55",
      closeButton: true,
      autoDismiss: 3 

    };
  }
    notificationAlertRef.current.notificationAlert(options);

  };



  const onSubmit = async (event) => {
    event.preventDefault();
    if (!passwordsMatch) {
      notify("Passwords do not match!", "danger");
      return;
    }

    try {
      const response = await signUp(email, password)

      if (response.success) {
       
        handleOpenEmailModal(); // Open the Email Verification Modal
      }
    } catch (err) {
 
      if (err.error.message === "User already exists") {
      
        notify("Try Signing In or use a new Email", "danger"); // Using notify instead of toast
        navigate("/auth/login", {
          replace: true,
          state: { email: err.email },
        });
      }
      else if (err.error.name === "InvalidPasswordException") {
       
          notify(
            null, // Keep `message` as null since we're using `fullMessage`
            "danger",
            <div>
              <strong>Password Requirements:</strong>
              <br /> • 8 Characters
              <br /> • 1 Capital Letter
              <br /> • 1 Special Character
              <br /> • 1 Number
            </div>
          );
              //Using notify instead of toast
         
      } else {
       
        notify(
          err.error.message ||
          "An error occurred during registration. Try Signing In or use a new Email",
          "danger"
        );
        navigate("/auth/register", { replace: true, state: { email: err.email } });
      }
    }
  };




  const handleResendVerification = () => {
    resendConfirmationCode(email)
  }
  



  const handleVerifyEmail = async () => {

    if (!verificationCode) {
      notify("Enter Verification Code!", "danger");
      return;
    }
    try {
      const response = await verifyEmail(verificationCode)
      if (response.success) {
      

        // Close the email verification modal
        handleCloseEmailModal();

        try{
        const response2 = await signInAfterVerification(email, password);

        if (response2.success) {
        

          // Navigate to the desired page after successful sign-in
          navigate("/auth/login", {
            replace: true,
            state: { email },
          });

     
        }
      }
      catch(err){
      
      }
      }
    }
    catch (err) {

      // Display an error notification
      notify(err.error || "Failed to verify email. Please try again.", "danger");
    }
  };


  useEffect(() => {
    // Check if passwords match whenever password or confirmPassword changes
    setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);



  return (
    <CoverLayout
      title="Welcome"
      color="white"
      description="Enter your email and password to sign up"
      image1={bgImage}
      image2={smImage}

    >
      <VuiBox component="form" role="form">


        <VuiBox mb={2}>
          <NotificationAlert ref={notificationAlertRef} />
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="email"
              placeholder="Your email..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              onChange={(e) => { setEmail(e.target.value) }}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              onChange={(e) => { setPassword(e.target.value) }}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Confirm Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}

            />
          </GradientBorder>
          {!passwordsMatch && confirmPassword && (
            <VuiBox style={{ color: '#f542d1' }}>
              Passwords do not match!
            </VuiBox>
          )}
        </VuiBox>
        {/* <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Remember me
          </VuiTypography>
        </VuiBox> */}
        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth onClick={onSubmit}>
            SIGN UP
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Already have an account?{" "}
            <VuiTypography
              component={Link}
              to="/auth/login"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign in
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      <Modal open={openEmailModal} onClose={handleCloseEmailModal} style={{ bgColor: 'white', width: '400px', margin: 'auto', marginTop: '50px' }}>
        <Card sx={{ paddingBottom: "15px" }}>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Verify Email
              </VuiTypography>
              <VuiTypography display="block" variant="button" color="white" my={1}>
                You will receive an e-mail in a few minutes
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography color="white">
                Verification Code
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="text"
                placeholder="Verification Code..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                  color: "white"
                })}
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}

              />
            </GradientBorder>
            <VuiBox mt={4} mb={1}>
              <VuiButton color="info" fullWidth onClick={handleVerifyEmail}>
                Verify Code for Email
              </VuiButton>
            </VuiBox>
            <VuiBox mt={4} mb={1}>
              <VuiButton color="info" fullWidth onClick={handleResendVerification}>
                Resend Code
              </VuiButton>
            </VuiBox>

          </VuiBox>


        </Card>
      </Modal>
    </CoverLayout>
  );
}

export default SignUp;
